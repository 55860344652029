import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IUserPermissions } from '@base_app/core/security/security.model';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { IUserOperator } from './security.model';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  constructor(private http: HttpClient) {}

  public getUserOperator(): Observable<IUserOperator> {
    return this.http.get<IUserOperator>(
      `${environment.api}/access-manager/v1/api/access-manager/user/operator`
    );
  }

  public getUserPermissions(): Observable<IUserPermissions> {
    return this.http.get<IUserPermissions>(
      `${environment.api}/access-manager/v1/api/access-manager/permissions/user`
    );
  }

  public getEnabledReleaseFeatureFlags(): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.api}/feature-flags/v1/api/feature-flags-wayside/release-flags/enabled`
    );
  }

  public getFeatureFlagsStatuses(
    operatorId: number
  ): Observable<Record<string, boolean>> {
    return this.http.get<Record<string, boolean>>(
      `${environment.api}/feature-flags/v1/api/feature-flags-wayside/operator-flags/operator/${operatorId}/statuses`
    );
  }
}
