import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, InjectionToken, NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

import { WINDOW } from '../services/window.service';
import { BlobErrorsInterceptor } from './interceptors/blob-errors.interceptor';
import { ErrorsInterceptor } from './interceptors/errors.interceptor';
import { MaintenanceInterceptor } from './interceptors/maintenance.interceptor';
import { SECURITY_EFFECTS } from './ngrx/security.effect';
import { securityReducers } from './ngrx/security.reducer';
import { appInitializer } from './utils/app.initializer';
import { keycloakMultiTenantInitializer } from './utils/keycloak-multi-tenant.initializer';

const appInitializerDependencies = new InjectionToken<(() => void)[]>(
  'appInitializerDependencies'
);

@NgModule({
  imports: [
    CommonModule,
    KeycloakAngularModule,
    StoreModule.forFeature('security', securityReducers),
    EffectsModule.forFeature([...SECURITY_EFFECTS]),
  ],
  declarations: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MaintenanceInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [HttpClient, appInitializerDependencies],
    },
    {
      provide: appInitializerDependencies,
      useFactory: (
        http: HttpClient,
        router: Router,
        keycloak: KeycloakService,
        windowService: Window
      ) => {
        return [
          keycloakMultiTenantInitializer(http, router, keycloak, windowService),
        ];
      },
      deps: [HttpClient, Router, KeycloakService, WINDOW],
    },
  ],
})
export class SecurityModule {}
