<ng-container *ngIf="isRecordingSupported; else recordingNotSupportedMessage">
  <ng-container [ngSwitch]="state">
    <ng-container *ngSwitchDefault>
      <button
        class="t-button warning"
        type="button"
        (click)="startRecording()"
      >
        <i
          class="t-icon t-icon-circle"
          aria-hidden="true"
        ></i
        >{{ 'global.audioRecorder.action.record' | translate }}
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="recorderState.RECORDING">
      <button
        class="t-button warning"
        type="button"
        *ngIf="timer$ | async as timer"
        (click)="stopRecording()"
      >
        <i
          class="t-icon t-icon-stop"
          aria-hidden="true"
        ></i
        >{{ 'global.audioRecorder.action.finish' | translate }}
        {{ timer | secondsToTime }}
      </button>

      <button
        class="t-button icon normal margin-left"
        (click)="pauseRecording()"
      >
        <i
          class="t-icon t-icon-pause"
          aria-hidden="true"
        ></i>
      </button>

      <button
        class="t-button icon normal margin-left"
        (click)="deleteRecording()"
      >
        <i
          class="t-icon t-icon-delete"
          aria-hidden="true"
        ></i>
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="recorderState.PAUSED">
      <button
        class="t-button normal"
        type="button"
        *ngIf="timer$ | async as timer"
        (click)="stopRecording()"
      >
        <i
          class="t-icon t-icon-stop"
          aria-hidden="true"
        ></i
        >{{ 'global.audioRecorder.action.paused' | translate }}
        {{ timer | secondsToTime }}
      </button>

      <button
        class="t-button icon normal margin-left pulse"
        (click)="resumeRecording()"
      >
        <i
          class="t-icon t-icon-pause"
          aria-hidden="true"
        ></i>
      </button>

      <button
        class="t-button icon normal margin-left"
        (click)="deleteRecording()"
      >
        <i
          class="t-icon t-icon-delete"
          aria-hidden="true"
        ></i>
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="recorderState.RECORDED">
      <button
        class="t-button warning"
        type="button"
        (click)="restartRecording()"
      >
        <i
          class="t-icon t-icon-refresh"
          aria-hidden="true"
        ></i
        >{{ 'global.audioRecorder.action.new' | translate }}
      </button>

      <button
        class="t-button icon normal margin-left"
        (click)="deleteRecording()"
      >
        <i
          class="t-icon t-icon-delete"
          aria-hidden="true"
        ></i>
      </button>
    </ng-container>
  </ng-container>

  <p
    class="message error tr-full-width"
    *ngIf="isRecordingAllowed === false"
  >
    <i
      class="t-icon t-icon-close-circle"
      aria-hidden="true"
    ></i>
    <span [translate]="'global.audioRecorder.message.notAllowed'"
      >Unable to access microphone, please check browser settings</span
    >
  </p>
</ng-container>

<ng-template #recordingNotSupportedMessage>
  <p class="message error">
    <i
      class="t-icon t-icon-close-circle"
      aria-hidden="true"
    ></i>
    <span [translate]="'global.audioRecorder.message.notSupported'"
      >Audio Recording is not supported in the browser</span
    >
  </p>
</ng-template>
