import { IStateWithAsync } from '@base_app/shared/model/ngrx.model';

import { IUserPermissions } from '../../security.model';

export interface IUserPermissionsState extends Readonly<IStateWithAsync> {
  userPermissions?: Readonly<IUserPermissions> | null;
}

export const userPermissionsInitialState: IUserPermissionsState = {
  pending: false,
  resolved: false,
  error: null,
  userPermissions: null,
};
