<div
  class="filterbar {{ styleClass }}"
  [hidden]="!showPanel"
>
  <h2
    class="t-h2"
    *ngIf="showTitle"
  >
    {{ 'global.filters.header' | translate }}
  </h2>

  <ng-content></ng-content>

  <div class="actions">
    <button
      class="t-button inline"
      type="button"
      *ngIf="showClearAll"
      (click)="clearFilters()"
    >
      {{ 'global.filters.clearAllMsg' | translate }}
    </button>

    <button
      class="t-button inline icon"
      type="button"
      *ngIf="showCloseIcon"
      (click)="close()"
    >
      <i
        class="t-icon t-icon-close"
        aria-hidden="true"
      ></i>
    </button>
  </div>
</div>
