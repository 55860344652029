<div
  [ngClass]="{
    error: !isValid,
    formElement: !horizontalLayout,
    'formElement-with-tooltip': tooltip,
    'p-grid p-align-start': horizontalLayout,
    'p-pb-1': horizontalLayout && !noGutter,
    'p-nogutter': horizontalLayout && noGutter
  }"
>
  <ng-container *ngIf="!horizontalLayout; else horizontalFormControlLayout">
    <label
      class="t-label formElement-label"
      [for]="elementIdForLabel"
      [ngClass]="{
        error: !isValid,
        'required-mark': isRequired
      }"
      [class]="gridColumnsAmount"
      >{{ label }}
    </label>
    <i
      class="tr-tooltip-icon t-icon t-icon-info"
      aria-hidden="true"
      [ngClass]="{
        't-icon-info': !warningIconTooltip,
        't-icon-alert-triangle color-orange': warningIconTooltip
      }"
      *ngIf="tooltip && !tooltipAfterContent"
      [pTooltip]="tooltip"
      [tooltipPosition]="tooltipPosition"
      appendTo="body"
    ></i>
  </ng-container>

  <ng-template #horizontalFormControlLayout>
    <div
      class="horizontal"
      [class]="gridColumnsAmount"
    >
      <label
        class="t-label p-pt-2"
        [for]="elementIdForLabel"
        [ngClass]="{
          error: !isValid,
          'required-mark': isRequired
        }"
        >{{ label }}
      </label>
      <i
        class="tr-tooltip-icon t-icon t-icon-info p-pt-2"
        aria-hidden="true"
        *ngIf="tooltip && !tooltipAfterContent"
        [pTooltip]="tooltip"
        [tooltipPosition]="tooltipPosition"
        appendTo="body"
      ></i>
    </div>
  </ng-template>

  <ng-content></ng-content>

  <i
    class="tr-tooltip-icon t-icon t-icon-info"
    aria-hidden="true"
    *ngIf="tooltip && tooltipAfterContent"
    [pTooltip]="tooltip"
    [tooltipPosition]="tooltipPosition"
    appendTo="body"
  ></i>
</div>
