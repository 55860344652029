<div
  class="titlebar"
  [hidden]="(visibleFlag$ | async) !== true"
>
  <nav class="inlinetabs">
    <ng-container *ngFor="let navLink of navLinks">
      <ng-container *ngxPermissionsExcept="navLink.forbiddenRoles">
        <a
          class="nav-link"
          *ngxPermissionsOnly="navLink.requiredRoles"
          [routerLink]="navLink.routerLink"
          routerLinkActive="active"
          >{{ navLink.label | translate }}
        </a>
      </ng-container>
    </ng-container>
  </nav>
</div>

<ng-content></ng-content>
