<ng-container
  *ngFor="let last = last; let breadcrumb; of: breadcrumbs$ | async"
>
  <ng-container
    *ngTemplateOutlet="
      breadcrumb.isCurrentRoute ? textBreadcrumb : linkBreadcrumb
    "
  ></ng-container>

  <span *ngIf="!last"> / </span>

  <ng-template #linkBreadcrumb>
    <a
      class="t-link t-link-no-underline"
      [routerLink]="[breadcrumb.url]"
    >
      <ng-container *ngTemplateOutlet="breadcrumbLabel"></ng-container>
    </a>
  </ng-template>

  <ng-template #textBreadcrumb>
    <ng-container *ngTemplateOutlet="breadcrumbLabel"></ng-container>
  </ng-template>

  <ng-template #breadcrumbLabel>
    <ng-container
      *ngFor="
        let lastBreadcrumbItem = last;
        let breadcrumbItem;
        of: breadcrumb.items
      "
    >
      <ng-container
        *ngTemplateOutlet="
          breadcrumbItem.label ? stringBreadcrumb : observableBreadcrumb
        "
      ></ng-container>

      <span *ngIf="!lastBreadcrumbItem"> / </span>

      <ng-template #stringBreadcrumb>
        {{ $any(breadcrumbItem.label) | translate }}
      </ng-template>

      <ng-template #observableBreadcrumb>
        {{ $any(breadcrumbItem.label$ | async) | translate }}
      </ng-template>
    </ng-container>
  </ng-template>
</ng-container>
