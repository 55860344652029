<button
  class="t-button inline icon"
  type="button"
  (click)="toggleMenu($event)"
>
  <i
    class="t-icon"
    aria-hidden="true"
    [ngClass]="mainIcon"
  ></i>
</button>

<p-menu
  #menu
  [model]="items"
  [popup]="true"
  appendTo="body"
></p-menu>
