import { Action, combineReducers } from '@ngrx/store';

import { EnabledReleaseFeatureFlagsState } from './enabled-release-feature-flags/enabled-release-feature-flags.model';
import { enabledReleaseFeatureFlagsReducer } from './enabled-release-feature-flags/enabled-release-feature-flags.reducer';
import { FeatureFlagsStatusesState } from './feature-flags-statuses/feature-flags-statuses.model';
import { featureFlagsStatusesReducer } from './feature-flags-statuses/feature-flags-statuses.reducer';
import { ISecurityState } from './security.state';
import { userOperatorReducer } from './user-operator/user-operator.reducer';
import { IUserOperatorState } from './user-operator/user-operator.state';
import { userPermissionsReducer } from './user-permissions/user-permissions.reducer';
import { IUserPermissionsState } from './user-permissions/user-permissions.state';

export const securityReducers = (
  state: ISecurityState | undefined,
  action: Action
): {
  enabledReleaseFeatureFlags: EnabledReleaseFeatureFlagsState;
  featureFlagsStatuses: FeatureFlagsStatusesState;
  userOperator: IUserOperatorState;
  userPermissions: IUserPermissionsState;
} =>
  combineReducers({
    enabledReleaseFeatureFlags: enabledReleaseFeatureFlagsReducer,
    featureFlagsStatuses: featureFlagsStatusesReducer,
    userOperator: userOperatorReducer,
    userPermissions: userPermissionsReducer,
  })(state, action);
