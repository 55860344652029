import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TabsNavService {
  public visibleFlag$ = new BehaviorSubject<boolean>(true);

  public getVisibilityFlag(): Observable<boolean> {
    return this.visibleFlag$.asObservable();
  }

  public setVisibilityFlag(value: boolean): void {
    this.visibleFlag$.next(value);
  }
}
