import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { SelectItem } from '@base_app/shared/classes/primengSelectItem';
import { isNotNullOrUndefined } from '@base_app/shared/utils/rxjs.util';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { FilterPanelService } from '../../filter-panel/filter-panel.service';
import {
  IStatusConfig,
  StatusesConstants,
  StatusType,
} from './statuses.constants';

@Component({
  selector: 'tr-statuses',
  templateUrl: './statuses.component.html',
})
export class StatusesComponent implements OnDestroy, OnInit {
  @Input() public statuses!: IStatusConfig[] | SelectItem[];
  @Input() public placeholder = 'global.form.status.label';

  @Input() public statusType: StatusType | null = null;
  @Input() public filterPropName = 'statuses';

  public selectedStatuses: IStatusConfig[] | SelectItem[] = [];

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private filterPanelService: FilterPanelService) {}

  public ngOnInit(): void {
    this.filterPanelService
      .getFilters()
      .pipe(
        isNotNullOrUndefined(),
        filter(filters => !filters[this.filterPropName]),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(() => (this.selectedStatuses = []));

    this.parseStatuses();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  public updateFilters(): void {
    this.filterPanelService.setFilterProp(this.filterPropName, [
      ...this.selectedStatuses,
    ]);
  }

  private parseStatuses(): void {
    if (this.statuses?.some(e => e instanceof SelectItem)) {
      return;
    }

    if (this.statuses) {
      this.statuses = StatusesConstants.parseStatuses(
        this.statuses as IStatusConfig[]
      );
    } else if (this.statusType) {
      this.statuses = StatusesConstants.getStatusesByType(this.statusType);
    }
  }
}
