import { Injectable } from '@angular/core';

import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class FilterPanelService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public filters: { [k: string]: any } = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public filters$ = new BehaviorSubject<any>({});

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getFilters(): Observable<any> {
    return this.filters$.asObservable();
  }

  public clearFilters(): void {
    this.filters = {};
    this.filters$.next({});
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setFilters(value: any): void {
    this.filters$.next(value);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setFilterProp(propName: string, value: any): void {
    this.filters = {
      ...this.filters,
      [propName]: value,
    };

    this.filters$.next(cloneDeep(this.filters));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setFilterProps(props: { [key: string]: any }): void {
    for (const prop of Object.keys(props)) {
      this.filters[prop] = props[prop];
    }

    this.filters$.next(this.filters);
  }

  public resetFilterProp(propName: string): void {
    if (!this.filters.hasOwnProperty(propName)) {
      return;
    }

    delete this.filters[propName];
    this.filters$.next(this.filters);
  }

  public resetFilterProps(propNames: string[], propagate: boolean): void {
    propNames.forEach(propName => delete this.filters[propName]);

    if (propagate) {
      this.filters$.next(this.filters);
    }
  }
}
