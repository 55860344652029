import { Pipe, PipeTransform } from '@angular/core';

import { isString } from 'lodash-es';

export type RgbColorType = 'red' | 'green' | 'blue';

@Pipe({
  name: 'rgbColorComponent',
})
export class RgbColorComponentPipe implements PipeTransform {
  public transform(value: string, type: RgbColorType): number {
    if (!this.isValidColor(value)) {
      throw new Error(`Invalid HEX color value`);
    }

    const normalizedHex = this.normalizeHex(value);

    switch (type) {
      case 'red':
        return this.getRedFromHex(normalizedHex);

      case 'green':
        return this.getGreenFromHex(normalizedHex);

      case 'blue':
        return this.getBlueFromHex(normalizedHex);

      default:
        throw new Error(`Invalid RGB component type: ${type}`);
    }
  }

  private isValidColor(hexColor: string): boolean {
    return (
      isString(hexColor) &&
      hexColor.startsWith('#') &&
      (hexColor.length === 4 ||
        hexColor.length === 5 ||
        hexColor.length === 7 ||
        hexColor.length === 9)
    );
  }

  private normalizeHex(hexColor: string): string {
    if (hexColor.length === 4 || hexColor.length === 5) {
      return `${hexColor[1]}${hexColor[1]}${hexColor[2]}${hexColor[2]}${hexColor[3]}${hexColor[3]}`;
    }

    return hexColor.slice(1, 7);
  }

  private getRedFromHex(hexColor: string): number {
    return parseInt(hexColor.slice(0, 2), 16);
  }

  private getGreenFromHex(hexColor: string): number {
    return parseInt(hexColor.slice(2, 4), 16);
  }

  private getBlueFromHex(hexColor: string): number {
    return parseInt(hexColor.slice(4, 6), 16);
  }
}
