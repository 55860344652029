import {
  ControlOptions,
  IconOptions,
  latLng,
  MapOptions,
  PathOptions,
  tileLayer,
} from 'leaflet';

export const defaultLeafletMapOptions: MapOptions = {
  zoom: 1,
  zoomControl: false,
  attributionControl: true,
  maxBounds: [
    [-90, -180],
    [90, 180],
  ],
  center: latLng(0, 0),
  worldCopyJump: true,
  layers: [
    tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>',
      maxZoom: 18,
    }),
  ],
};

export const defaultLeafletMapPathOptions: PathOptions = {
  fillColor: '#FF0012',
  color: '#FF0012',
  stroke: true,
  dashArray: '6',
  weight: 1,
};

export const defaultLeafletMapZoomControlOptions: ControlOptions = {
  position: 'bottomleft',
};

export const defaultLeafletMapTrainIconOptions: IconOptions = {
  iconUrl: '/assets/images/railway-black.png',
  shadowUrl: '/assets/images/railway-shadow.png',
  iconSize: [20, 20],
  shadowSize: [22.5, 22.5],
  iconAnchor: [10, 10],
  shadowAnchor: [11.25, 11.25],
  popupAnchor: [0, -15],
};
