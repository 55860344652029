import { createReducer, on } from '@ngrx/store';

import { setPreviousRoute } from './previous-route.actions';
import { PreviousRouteState } from './previous-route.model';

export const previousRouteInitialState: PreviousRouteState = {
  state: null,
};

export const previousRouteReducer = createReducer(
  previousRouteInitialState,
  on(setPreviousRoute, (state, { previousRoute }) => ({
    ...state,
    state: previousRoute,
  }))
);
