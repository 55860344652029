import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';

import { Control, ControlPosition, DomEvent, DomUtil, Map } from 'leaflet';

@Component({
  selector: 'tr-leaflet-custom-button',
  templateUrl: './leaflet-custom-button.component.html',
  styleUrls: ['./leaflet-custom-button.component.scss'],
})
export class LeafletCustomButtonComponent implements OnDestroy {
  @Input() public identifier = 'leaflet-custom-button';
  @Input() public label: string | null = null;
  @Input() public buttonClass: string | null = 'icon inline';
  @Input() public iconClass: string | null = 't-icon-refresh';
  @Input() public defaultShape = true;
  @Input() public position: ControlPosition = 'topleft';
  @Input() public tooltip = '';

  @Output() public clicked: EventEmitter<void> = new EventEmitter();

  private internalMapReference: Map | null = null;
  private customLeafletButtonControl: Control | null = null;

  constructor() {}

  @Input() public set map(map: Map) {
    if (map) {
      this.internalMapReference = map;
      // wait for view to update identifier
      setTimeout(() => {
        const customLeafletButtonElement: HTMLElement | null = DomUtil.get(
          this.identifier
        );
        const customLeafletButtonControl = Control.extend({
          onAdd: () => customLeafletButtonElement,
        });

        // prevent other click handlers on map to be executed
        if (customLeafletButtonElement) {
          DomEvent.disableClickPropagation(customLeafletButtonElement);
        }

        this.customLeafletButtonControl = new customLeafletButtonControl({
          position: this.position,
        }).addTo(map);
      }, 0);
    }
  }

  public ngOnDestroy() {
    if (this.internalMapReference && this.customLeafletButtonControl) {
      this.internalMapReference.removeControl(this.customLeafletButtonControl);
    }
  }
}
