import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { of } from 'rxjs';
import { map, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { getPageTitle } from '../routing/store/routing.selectors';

@Injectable()
export class PageTitleEffects {
  public setPageTitle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerNavigatedAction),
        withLatestFrom(this.store$.pipe(select(getPageTitle))),
        map(([_routerNavigatedAction, pageTitle]) => pageTitle),
        switchMap(pageTitle => {
          if (pageTitle) {
            return this.translate.onLangChange.pipe(
              startWith(''),
              switchMap(() => this.translate.get(pageTitle))
            );
          }

          return of(environment.appName);
        }),
        tap(pageTitle => this.title.setTitle(pageTitle))
      ),
    { dispatch: false }
  );

  constructor(
    private store$: Store,
    private actions$: Actions,
    private translate: TranslateService,
    private title: Title
  ) {}
}
