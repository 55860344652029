import { HttpErrorResponse } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';

import { IUserPermissions } from '../../security.model';

export const getUserPermissions = createAction(
  '[Security] Get User Permissions'
);

export const getUserPermissionsSuccess = createAction(
  '[Security] Get User Permissions Success',
  props<{ permissions: IUserPermissions }>()
);

export const getUserPermissionsFail = createAction(
  '[Security] Get User Permissions Fail',
  props<{ error: HttpErrorResponse }>()
);

export const clearUserPermissionsState = createAction(
  '[Security] Clear User Permissions'
);
