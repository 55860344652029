import { HttpErrorResponse } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';

export const loadFeatureFlagsStatuses = createAction(
  '[Security] Load Feature Flags Statuses',
  props<{ operatorId: number }>()
);

export const loadFeatureFlagsStatusesSuccess = createAction(
  '[Security] Load Feature Flags Statuses Success',
  props<{ featureFlagsStatuses: Record<string, boolean> }>()
);

export const loadFeatureFlagsStatusesFail = createAction(
  '[Security] Load Feature Flags Statuses Fail',
  props<{ error: HttpErrorResponse }>()
);

export const setFeatureFlagStatus = createAction(
  '[Security] Feature Flag Enable',
  props<{ featureFlagKey: string; enabled: boolean }>()
);
