import { isSuccessfullyResolvedStateWithAsync } from '@base_app/shared/model/ngrx.model';
import { createSelector } from '@ngrx/store';

import { getSecurityState } from '../security.selectors';

export const getSecurityUserOperatorState = createSelector(
  getSecurityState,
  security => security?.userOperator || null
);

export const getSecurityUserOperator = createSelector(
  getSecurityUserOperatorState,
  userOperatorState => userOperatorState?.operatorId ?? null
);

export const isSecurityUserOperatorSuccessfullyResolved = createSelector(
  getSecurityUserOperatorState,
  isSuccessfullyResolvedStateWithAsync
);
