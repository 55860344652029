import { Component } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { acceptMaintenanceBannerAction, IPlannedMaintenance } from '../store';
import {
  getPlannedMaintenance,
  getPlannedMaintenanceShowBannerStatus,
} from '../store/planned-maintenance.selectors';

@Component({
  selector: 'tr-planned-maintenance-banner',
  templateUrl: './planned-maintenance-banner.component.html',
  styleUrls: ['./planned-maintenance-banner.component.scss'],
})
export class PlannedMaintenanceBannerComponent {
  public plannedMaintenance$!: Observable<IPlannedMaintenance | null>;
  public showPlannedMaintenanceBanner$!: Observable<boolean>;

  constructor(private store$: Store) {
    this.plannedMaintenance$ = this.store$.pipe(select(getPlannedMaintenance));
    this.showPlannedMaintenanceBanner$ = this.store$.pipe(
      select(getPlannedMaintenanceShowBannerStatus)
    );
  }

  public acceptMaintenanceBanner(
    plannedMaintenance: IPlannedMaintenance
  ): void {
    this.store$.dispatch(acceptMaintenanceBannerAction({ plannedMaintenance }));
  }
}
