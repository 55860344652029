<ng-container *ngIf="showPlannedMaintenanceBanner$ | async">
  <div
    class="p-d-flex p-ai-center margin"
    *ngIf="plannedMaintenance$ | async as plannedMaintenance"
  >
    <div class="p-mr-3">
      <strong>Scheduled Maintenance</strong>

      <p>
        {{
          'global.plannedMaintenance.message'
            | translate
              : {
                  maintenanceStart:
                    plannedMaintenance.maintenanceStart | date: 'medium',
                  maintenanceEnd:
                    plannedMaintenance.maintenanceEnd | date: 'medium'
                }
        }}
      </p>
    </div>

    <button
      class="t-button normal p-ml-auto"
      type="button"
      (click)="acceptMaintenanceBanner(plannedMaintenance)"
    >
      {{ 'global.action.ok' | translate }}
    </button>
  </div>
</ng-container>
