import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ToasterService } from '../../../shared/services/toaster.service';
import { IHttpResponseWithError } from './errors.model';

export const BYPASS_ERROR_MESSAGE = new HttpContextToken(() => false);

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  constructor(private toaster: ToasterService) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.context.get(BYPASS_ERROR_MESSAGE) === true) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessages: string[] = this.getErrorMessagesFromError(error);

        for (const errMessage of errorMessages) {
          this.toaster.showError(
            { message: errMessage },
            'global.response.title.error'
          );
        }

        return throwError(() => error);
      })
    );
  }

  private getErrorMessagesFromError(error: unknown): string[] {
    if (this.isError(error)) {
      return error.error.errors.map(errorResponse => errorResponse.message);
    }

    return ['global.toasterMsg.requestFailed'];
  }

  private isError(error: unknown): error is IHttpResponseWithError {
    return !!(error as IHttpResponseWithError)?.error?.errors?.[0]?.message;
  }
}
