import { Injectable } from '@angular/core';

import { SelectItem } from '@base_app/shared/classes/primengSelectItem';

@Injectable({
  providedIn: 'root',
})
export class PrimengHelperService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public formSelectItem<T extends Record<string, any>>(
    arr: T[],
    props?: { value: string; label?: string }
  ): SelectItem<T>[] {
    if (props) {
      return arr.map(
        v =>
          new SelectItem(
            props.value ? v[props.value] : v,
            props.label ? v[props.label] : v
          )
      );
    }

    return arr.map(v => new SelectItem(v));
  }
}
