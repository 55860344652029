import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IPlannedMaintenanceState } from './planned-maintenance.model';

export const getPlannedMaintenanceState =
  createFeatureSelector<IPlannedMaintenanceState>('plannedMaintenance');

export const getPlannedMaintenance = createSelector(
  getPlannedMaintenanceState,
  state => state && state.plannedMaintenance
);

export const getPlannedMaintenanceShowBannerStatus = createSelector(
  getPlannedMaintenanceState,
  state => state && state.showBanner
);
