import { HttpErrorResponse } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';

export const loadEnabledReleaseFeatureFlags = createAction(
  '[Security] Load Enabled Release Feature Flags'
);

export const loadEnabledReleaseFeatureFlagsSuccess = createAction(
  '[Security] Load Enabled Release Feature Flags Success',
  props<{
    enabledReleaseFeatureFlags: string[];
  }>()
);

export const loadEnabledReleaseFeatureFlagsFail = createAction(
  '[Security] Load Enabled Release Feature Flags Fail',
  props<{ error: HttpErrorResponse }>()
);
