<div class="tr-filter-control {{ styleClass }}">
  <h5
    class="t-h5"
    *ngIf="showTitle"
  >
    {{ 'global.filters.functionalityName.header' | translate }}
  </h5>

  <tr-functionality-name
    [(ngModel)]="selectedFunctionality"
    [functionalities$]="functionalities$"
    (valueChanged)="updateFilters()"
  ></tr-functionality-name>
</div>
