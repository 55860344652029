<button
  class="t-button button icon normal"
  type="button"
  (click)="backToPrevPage()"
>
  <i
    class="t-icon t-icon-arrow-left"
    aria-hidden="true"
  ></i>
</button>
