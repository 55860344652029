<span
  class="tag green"
  *ngIf="isReleased && !isArchived"
>
  {{ 'global.state.available' | translate }}
</span>

<span
  class="tag grey"
  *ngIf="!isReleased && !isArchived"
>
  {{ 'global.state.draft' | translate }}
</span>

<span
  class="tag yellow"
  *ngIf="isArchived"
>
  {{ 'global.state.archived' | translate }}
</span>
