export class StringUtil {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static filterByString<T extends Record<string, any>>(
    query: string,
    entities: T[],
    propNamesToFilterBy: string[] = ['name']
  ): T[] {
    const isArrayOfObjects = this.isArrayOfObjects(entities);

    if (isArrayOfObjects) {
      return entities.filter(entity =>
        this.isInProperties(entity, propNamesToFilterBy, query)
      );
    }

    return entities.filter(entity =>
      this.isInProperty(entity as unknown as string, query)
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static isArrayOfObjects(entities: any): boolean {
    return (
      Array.isArray(entities) && entities[0] && typeof entities[0] === 'object'
    );
  }

  private static isInProperty(property: string, query: string): boolean {
    return property.toLocaleLowerCase().includes(query.toLocaleLowerCase());
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static isInProperties<T extends Record<string, any>>(
    entity: T,
    propNamesToFilterBy: string[],
    query: string
  ): boolean {
    return propNamesToFilterBy.some(property => {
      const elementToCheck = entity[property];

      return !!elementToCheck && this.isInProperty(elementToCheck, query);
    });
  }
}
