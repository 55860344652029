<div class="tr-row-card horizontal align-center">
  <span class="fillspace">{{ label }}</span>

  <div class="tr-card-actions horizontal align-center">
    <div *ngIf="showActions">
      <button
        class="t-button icon inline"
        type="button"
        *ngIf="showEdit"
        [pTooltip]="'global.action.edit' | translate"
        tooltipPosition="top"
        (click)="editClicked($event)"
      >
        <i
          class="t-icon t-icon-edit"
          aria-hidden="true"
        ></i>
      </button>

      <button
        class="t-button icon inline"
        type="button"
        *ngIf="showView"
        [pTooltip]="'global.action.view' | translate"
        tooltipPosition="top"
        (click)="viewClicked($event)"
      >
        <i
          class="t-icon t-icon-eye"
          aria-hidden="true"
        ></i>
      </button>

      <button
        class="t-button icon inline"
        type="button"
        *ngIf="showArchive"
        [pTooltip]="'global.action.archive' | translate"
        tooltipPosition="top"
        (click)="archive.emit(true)"
      >
        <i
          class="t-icon t-icon-archive"
          aria-hidden="true"
        ></i>
      </button>

      <button
        class="t-button icon inline"
        type="button"
        *ngIf="showDelete"
        [pTooltip]="'global.action.unarchive' | translate"
        tooltipPosition="top"
        (click)="archive.emit()"
      >
        <i
          class="t-icon t-icon-upload"
          aria-hidden="true"
        ></i>
      </button>

      <button
        class="t-button icon inline"
        type="button"
        *ngIf="showDelete"
        [pTooltip]="'global.action.delete' | translate"
        tooltipPosition="top"
        (click)="delete.emit()"
      >
        <i
          class="t-icon t-icon-delete"
          aria-hidden="true"
        ></i>
      </button>
    </div>

    <i
      class="tr-selectedicon t-icon t-icon-chevron-right"
      aria-hidden="true"
      *ngIf="hasNesting"
    ></i>
  </div>
</div>
