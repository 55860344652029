import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { isString } from 'lodash-es';

@Pipe({
  name: 'searchTranslated',
})
export class SearchTranslatedPipe implements PipeTransform {
  constructor(public translateService: TranslateService) {}

  public transform(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    collection: Record<string, any>[] | null,
    property: string,
    text: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Record<string, any>[] | null {
    if (!collection) {
      return collection;
    }

    return collection.filter(item => {
      if (!item[property] || !isString(item[property])) {
        return false;
      }

      return this.translateService
        .instant(item[property])
        .toLowerCase()
        .includes(text.toLowerCase());
    });
  }
}
