import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { SecurityService } from '../../security.service';
import {
  getUserOperator,
  getUserOperatorFail,
  getUserOperatorSuccess,
} from './user-operator.actions';

@Injectable()
export class UserOperatorEffects {
  public getUserOperator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserOperator),
      switchMap(() =>
        this.securityService.getUserOperator().pipe(
          map(({ operatorId }) => getUserOperatorSuccess({ operatorId })),
          catchError(error => of(getUserOperatorFail({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private securityService: SecurityService
  ) {}
}
