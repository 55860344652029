import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { select, Store } from '@ngrx/store';

import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';

import { loadEnabledReleaseFeatureFlags } from '../ngrx/enabled-release-feature-flags/enabled-release-feature-flags.actions';
import {
  areEnabledReleaseFeatureFlagsSuccessfullyResolved,
  getEnabledReleaseFeatureFlags,
} from '../ngrx/enabled-release-feature-flags/enabled-release-feature-flags.selectors';

export const securityEnabledReleaseFeatureFlagsGuard: CanActivateFn =
  (): Observable<boolean> => {
    const store$: Store = inject(Store);
    const permissionsService: NgxPermissionsService = inject(
      NgxPermissionsService
    );

    store$.dispatch(loadEnabledReleaseFeatureFlags());

    return store$.pipe(
      select(areEnabledReleaseFeatureFlagsSuccessfullyResolved),
      filter(isSuccessfullyResolved => isSuccessfullyResolved),
      switchMap(() => store$.pipe(select(getEnabledReleaseFeatureFlags))),
      tap(enabledReleaseFeatureFlags => {
        if (enabledReleaseFeatureFlags) {
          permissionsService.addPermission([...enabledReleaseFeatureFlags]);
        }
      }),
      map(() => true),
      first()
    );
  };
