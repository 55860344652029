import { Component } from '@angular/core';

import { back } from '@base_app/core/routing/store/routing.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'tr-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent {
  constructor(private store$: Store) {}

  public backToPrevPage(): void {
    this.store$.dispatch(back());
  }
}
