<div
  class="tr-filter-control horizontal"
  id="tr-filter-date-range"
>
  <label
    class="t-label"
    for="fromDate"
    >{{ beforeLabel | translate }}</label
  >

  <p-calendar
    [(ngModel)]="afterDate"
    (onSelect)="updateMinDateFilter($event)"
    [maxDate]="$any(beforeDate)"
    [showIcon]="true"
    selectionMode="single"
    inputId="fromDate"
  ></p-calendar>

  <label
    class="t-label"
    for="toDate"
    >{{ 'global.filters.dateRange.toLabel' | translate }}</label
  >

  <p-calendar
    [(ngModel)]="beforeDate"
    (onSelect)="updateMaxDateFilter($event)"
    [minDate]="$any(afterDate)"
    [showIcon]="true"
    selectionMode="single"
    inputId="toDate"
  ></p-calendar>
</div>
