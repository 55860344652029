import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  PRIMARY_OUTLET,
  Route,
  Router,
} from '@angular/router';

import { MemoizedSelector, select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

import {
  BreadcrumbDataBreadcrumb,
  IBreadcrumb,
  IRouteWithBreadcrumbData,
} from './breadcrumbs.model';

@Component({
  selector: 'tr-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
  public breadcrumbs$!: Observable<IBreadcrumb[] | null>;

  private readonly routeDataBreadcrumb: keyof IRouteWithBreadcrumbData =
    'breadcrumb' as keyof IRouteWithBreadcrumbData;

  constructor(
    private store$: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.breadcrumbs$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      startWith({}),
      distinctUntilChanged(),
      map(() => this.buildBreadCrumb(this.activatedRoute.root))
    );
  }

  private buildBreadCrumb(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: IBreadcrumb[] = []
  ): IBreadcrumb[] | null {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      url = this.buildUrl(url, child);

      if (this.isRouteWithBreadcrumbData(child.snapshot.routeConfig)) {
        const breadcrumb: IBreadcrumb = {
          url,
          isCurrentRoute: this.isCurrentRoute(child),
          items: [],
        };
        const breadcrumbData: BreadcrumbDataBreadcrumb =
          child.snapshot.routeConfig.data[this.routeDataBreadcrumb];

        breadcrumbData.forEach(breadcrumbDataItem => {
          if (this.isSelectorBreadcrumb(breadcrumbDataItem)) {
            breadcrumb.items.push({
              label$: this.store$.pipe(select(breadcrumbDataItem)),
            });
          } else {
            breadcrumb.items.push({
              label: breadcrumbDataItem,
            });
          }
        });

        breadcrumbs.push(breadcrumb);
      }

      return this.buildBreadCrumb(child, url, breadcrumbs);
    }

    return null;
  }

  private isRouteWithBreadcrumbData(
    route: Route | null
  ): route is IRouteWithBreadcrumbData {
    if (!route?.data) {
      return false;
    }

    return route.data.hasOwnProperty(this.routeDataBreadcrumb);
  }

  private isSelectorBreadcrumb(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    breadcrumb: string | MemoizedSelector<any, any>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): breadcrumb is MemoizedSelector<any, any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (breadcrumb as MemoizedSelector<any, any>).projector !== undefined;
  }

  private isCurrentRoute(activatedRoute: ActivatedRoute): boolean {
    if (activatedRoute.children.length === 0) {
      return true;
    }

    const childrenInPrimaryOutlet = activatedRoute.children.filter(
      child => child.outlet === PRIMARY_OUTLET
    );

    if (childrenInPrimaryOutlet.length === 0) {
      return true;
    }

    if (childrenInPrimaryOutlet[0].snapshot.url.length === 0) {
      return this.isCurrentRoute(childrenInPrimaryOutlet[0]);
    }

    return false;
  }

  private buildUrl(parentUrl: string, route: ActivatedRoute): string {
    const routeUrl: string = route.snapshot.url
      .map(segment => segment.path)
      .join('/');

    if (routeUrl) {
      return `${parentUrl}/${routeUrl}`;
    }

    return parentUrl;
  }
}
