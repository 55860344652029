import { createReducer, on } from '@ngrx/store';

import { merge, uniq } from 'lodash-es';

import {
  getTranslationPartial,
  getTranslationPartialError,
  getTranslationPartialSuccess,
  reloadRequiredPartials,
  reloadRequiredPartialsError,
  reloadRequiredPartialsSuccess,
  removeTranslationPartial,
  setCurrentLanguageKey,
} from './i18n.actions';
import { I18N_INITIAL_STATE } from './i18n.model';

export const i18nReducer = createReducer(
  I18N_INITIAL_STATE,

  on(getTranslationPartial, reloadRequiredPartials, state => ({
    ...state,
    pending: true,
  })),

  on(removeTranslationPartial, (state, { partialName }) => ({
    ...state,
    requiredPartials: state.requiredPartials.filter(
      requiredPartialName => requiredPartialName !== partialName
    ),
  })),

  on(getTranslationPartialSuccess, (state, { partialName, languageKey }) => {
    const partials = state.loadedLangs[languageKey]
      ? state.loadedLangs[languageKey].partials
      : [];

    return {
      ...state,
      pending: false,
      resolved: true,
      error: null,
      loadedLangs: merge({}, state.loadedLangs, {
        [languageKey]: { partials: [...partials, partialName] },
      }),
      requiredPartials: uniq([...state.requiredPartials, partialName]),
    };
  }),

  on(
    getTranslationPartialError,
    reloadRequiredPartialsError,
    (state, { error }) => ({
      ...state,
      pending: false,
      resolved: true,
      error,
    })
  ),

  on(reloadRequiredPartialsSuccess, (state, { partialNames, languageKey }) => {
    const partials = state.loadedLangs[languageKey]
      ? state.loadedLangs[languageKey].partials
      : [];

    return {
      ...state,
      pending: false,
      resolved: true,
      error: null,
      loadedLangs: merge({}, state.loadedLangs, {
        [languageKey]: { partials: [...partials, ...partialNames] },
      }),
      requiredPartials: uniq([...state.requiredPartials, ...partialNames]),
    };
  }),

  on(setCurrentLanguageKey, (state, { languageKey }) => ({
    ...state,
    currentLang: languageKey,
  }))
);
