import { createFeatureSelector, createSelector } from '@ngrx/store';

import { difference } from 'lodash-es';

import { II18nState } from './i18n.model';

export const getI18nState = createFeatureSelector<II18nState>('i18n');

export const getCurrentLanguage = createSelector(
  getI18nState,
  i18nState => i18nState?.currentLang || null
);

export const arePartialsLoadedForCurrentLanguage = (partials: string[]) =>
  createSelector(getI18nState, i18nState => {
    if (
      !i18nState ||
      !i18nState.currentLang ||
      !i18nState.loadedLangs[i18nState.currentLang] ||
      !i18nState.loadedLangs[i18nState.currentLang].partials
    ) {
      return false;
    }

    const notLoadedPartials = difference(
      partials,
      i18nState.loadedLangs[i18nState.currentLang].partials
    );

    return notLoadedPartials.length === 0;
  });

export const getRequiredPartialsToRefetch = createSelector(
  getI18nState,
  i18nState => {
    if (!i18nState) {
      return null;
    }

    if (
      !i18nState.requiredPartials ||
      i18nState.requiredPartials.length === 0
    ) {
      return null;
    }

    if (!i18nState.loadedLangs[i18nState.currentLang]) {
      return i18nState.requiredPartials;
    }

    const partialsToFetch = difference(
      i18nState.requiredPartials,
      i18nState.loadedLangs[i18nState.currentLang].partials
    );

    if (!partialsToFetch || partialsToFetch.length === 0) {
      return null;
    }

    return partialsToFetch;
  }
);
