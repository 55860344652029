import { Component, Input } from '@angular/core';

@Component({
  selector: 'tr-validation-error-text',
  templateUrl: './validation-error-text.component.html',
  styleUrls: ['./validation-error-text.component.scss'],
})
export class ValidationErrorTextComponent {
  @Input() public isError = true;
  @Input() public isDisabled = false;
}
