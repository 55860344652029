import { Pipe, PipeTransform } from '@angular/core';

import { ITrainLocation } from '@base_app/shared/model/train.model';

import { isTrainLocationValid } from '../trains-map/trains-map.utils';

@Pipe({
  name: 'isTrainLocationValid',
})
export class IsTrainLocationValidPipe implements PipeTransform {
  public transform(location: ITrainLocation): boolean {
    return isTrainLocationValid(location);
  }
}
