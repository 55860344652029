<div
  class="tr-filter-control"
  id="tr-filter-search"
>
  <input
    class="tr-search t-textinput"
    type="text"
    trim="blur"
    [(ngModel)]="searchString"
    (keyup)="onKeyup($event)"
    [placeholder]="placeholder | translate"
  />

  <button
    class="t-button icon tr-search-icon"
    type="button"
    (click)="updateFilter($event)"
  >
    <i
      class="fa fa-search"
      aria-hidden="true"
    ></i>
  </button>
</div>
