import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { SimpleChangesTyped } from '@base_app/shared/utils/angular.utils';

@Component({
  selector: 'tr-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardComponent implements OnChanges {
  @Input() public titles: string[] = [];
  @Input() public iconClasses = '';
  @Input() public styleClasses = '';
  @Input() public maxTitles = 2;
  @Input() public tooltipSeparator = '\n';

  public showEllipsis = false;

  public ngOnChanges({ titles, maxTitles }: SimpleChangesTyped<CardComponent>) {
    if (titles || maxTitles) {
      this.showEllipsis = this.titles.length > this.maxTitles;
    }
  }
}
