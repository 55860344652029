import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToTime',
})
export class SecondsToTimePipe implements PipeTransform {
  public transform(timeInSeconds: number): string {
    const separator = ':';
    let time = '';

    if (!timeInSeconds) {
      return `00${separator}00`;
    }

    const hours = Math.floor(timeInSeconds / 3600);
    const timeInSecondsWithoutHours = timeInSeconds - hours * 3600;
    const minutes = Math.floor(timeInSecondsWithoutHours / 60);
    const seconds = timeInSecondsWithoutHours - minutes * 60;

    if (hours) {
      time = `${this.makeTwoDigit(hours)}${separator}`;
    }

    time = `${time}${this.makeTwoDigit(minutes)}${separator}${this.makeTwoDigit(
      seconds
    )}`;

    return time;
  }

  private makeTwoDigit(digit: number): string {
    if (digit < 10) {
      return `0${digit}`;
    }

    return `${digit}`;
  }
}
