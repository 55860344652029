import { CommonModule } from '@angular/common';
import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { TranslateModule } from '@ngx-translate/core';
import { PreloaderModule } from '@televic/prime-widgets/preloader';
import { SidebarModule } from '@televic/prime-widgets/sidebar';
import { TableDonutModule } from '@televic/prime-widgets/table-donut';
import { WizardModule } from '@televic/prime-widgets/wizard';

import { NgxPermissionsModule } from 'ngx-permissions';
import { NgPipesModule } from 'ngx-pipes';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PickListModule } from 'primeng/picklist';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SpinnerModule } from 'primeng/spinner';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';

import { AppSwitcherComponent } from './app-switcher/app-switcher.component';
import { ArchivedFilterComponent } from './archived-filter/archived-filter.component';
import { AudioFilePlayerComponent } from './audio-file-player/audio-file-player.component';
import { AudioRecorderComponent } from './audio-recorder/audio-recorder.component';
import { AutocompleteDropdownFilterComponent } from './autocompletes/autocomplete-dropdown-filter/autocomplete-dropdown-filter.component';
import { AutocompleteDropdownComponent } from './autocompletes/autocomplete-dropdown/autocomplete-dropdown.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { BaseShellComponent } from './base-shell/base-shell.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { CardComponent } from './card/card.component';
import { InputComponent } from './controls/input/input.component';
import { MenuItemIconComponent } from './controls/menu-item-icon/menu-item-icon.component';
import { MaxLengthDirective } from './directives/max-length/max-length.directive';
import { NgxPermissionsOnlyAndDirective } from './directives/ngx-permissions-only-and/ngx-permissions-only-and.directive';
import { NgxPermissionsOnlyEntitySpecificDirective } from './directives/ngx-permissions-only-entity-specific/ngx-permissions-only-entity-specific.directive';
import { OnlyPositiveIntegerDirective } from './directives/only-positive-integer/only-positive-integer.directive';
import { DownloadTagComponent } from './download-tag/download-tag.component';
import { DurationInputComponent } from './duration-input/duration-input.component';
import { CheckboxFilterComponent } from './filter-controls/checkbox/checkbox-filter.component';
import { DateRangeComponent } from './filter-controls/date-range/date-range.component';
import { DropdownValueComponent } from './filter-controls/dropdown-value/dropdown-value.component';
import { FilterRelationComponent } from './filter-controls/filter-relation/filter-relation.component';
import { FunctionalityNameFilterContainerComponent } from './filter-controls/functionality-name/functionality-name-filter.container';
import { SearchComponent } from './filter-controls/search/search.component';
import { StatusesComponent } from './filter-controls/statuses/statuses.component';
import { FilterPanelComponent } from './filter-panel/filter-panel.component';
import { FormGroupComponent } from './form-group/form-group.component';
import { FunctionalityNameComponent } from './functionality-name/functionality-name.component';
import { ItemActionsSetComponent } from './item-actions-set/item-actions-set.component';
import { LeafletCustomButtonComponent } from './leaflet-custom-button/leaflet-custom-button.component';
import { MainNavigationActionsComponent } from './main-navigation-actions/main-navigation-actions.component';
import { IsTrainLocationValidPipe } from './pipes/is-train-location-valid.pipe';
import { JoinByPipe } from './pipes/joinBy.pipe';
import { RgbColorComponentPipe } from './pipes/rgb-color.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SearchTranslatedPipe } from './pipes/search-translated';
import { SecondsToTimePipe } from './pipes/seconds-to-time.pipe';
import { RowCardWithActionsComponent } from './row-card-with-actions/row-card-with-actions.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { StatusIndicatorComponent } from './status-indicator/status-indicator.component';
import { StatusTagComponent } from './status-tag/status-tag.component';
import { TabsNavComponent } from './tabs-nav/tabs-nav.component';
import { TextTruncatedComponent } from './text-truncated/text-truncated.component';
import { TrainsMapMarkerPopupComponent } from './trains-map/trains-map-marker-popup/trains-map-marker-popup.component';
import { TrainsMapComponent } from './trains-map/trains-map.component';
import { UrlStateIndicatorComponent } from './url-state-indicator/url-state-indicator.component';
import { ValidationErrorTextComponent } from './validation-error-text/validation-error-text.component';

const SHARED_COMPONENTS = [
  AppSwitcherComponent,
  ArchivedFilterComponent,
  AudioFilePlayerComponent,
  AudioRecorderComponent,
  AutocompleteDropdownComponent,
  AutocompleteDropdownFilterComponent,
  BackButtonComponent,
  BaseShellComponent,
  BreadcrumbsComponent,
  CardComponent,
  CheckboxFilterComponent,
  DateRangeComponent,
  DownloadTagComponent,
  DropdownValueComponent,
  DurationInputComponent,
  FilterPanelComponent,
  FilterRelationComponent,
  FormGroupComponent,
  FunctionalityNameComponent,
  FunctionalityNameFilterContainerComponent,
  InputComponent,
  IsTrainLocationValidPipe,
  ItemActionsSetComponent,
  JoinByPipe,
  LeafletCustomButtonComponent,
  MainNavigationActionsComponent,
  MaxLengthDirective,
  MenuItemIconComponent,
  NgxPermissionsOnlyAndDirective,
  NgxPermissionsOnlyEntitySpecificDirective,
  OnlyPositiveIntegerDirective,
  RgbColorComponentPipe,
  RowCardWithActionsComponent,
  SafePipe,
  SearchComponent,
  SearchTranslatedPipe,
  SecondsToTimePipe,
  SpinnerComponent,
  StatusIndicatorComponent,
  StatusTagComponent,
  StatusesComponent,
  TabsNavComponent,
  TextTruncatedComponent,
  TrainsMapComponent,
  TrainsMapMarkerPopupComponent,
  UrlStateIndicatorComponent,
  ValidationErrorTextComponent,
];

const SHARED_3RD_PARTY_COMPONENTS = [
  AccordionModule,
  AutoCompleteModule,
  ButtonModule,
  CalendarModule,
  CheckboxModule,
  ChipsModule,
  ColorPickerModule,
  ConfirmDialogModule,
  DialogModule,
  DropdownModule,
  FileUploadModule,
  FormsModule,
  InputMaskModule,
  InputNumberModule,
  InputSwitchModule,
  LeafletMarkerClusterModule,
  LeafletModule,
  ListboxModule,
  MenuModule,
  MenubarModule,
  MessageModule,
  MessagesModule,
  MultiSelectModule,
  NgPipesModule,
  NgxPermissionsModule,
  NgxTrimDirectiveModule,
  OverlayPanelModule,
  PaginatorModule,
  PanelModule,
  PickListModule,
  PreloaderModule,
  RadioButtonModule,
  ReactiveFormsModule,
  RouterModule,
  ScrollPanelModule,
  SelectButtonModule,
  SidebarModule,
  SpinnerModule,
  TabViewModule,
  TableDonutModule,
  TableModule,
  ToastModule,
  TooltipModule,
  TranslateModule,
  WizardModule,
];

@NgModule({
  imports: [CommonModule, ...SHARED_3RD_PARTY_COMPONENTS],
  exports: [...SHARED_3RD_PARTY_COMPONENTS, ...SHARED_COMPONENTS],
  declarations: [...SHARED_COMPONENTS],
})
export class SharedComponentsModule {
  constructor(private injector: Injector) {
    const mapMarkerPopupCustomElement = createCustomElement(
      TrainsMapMarkerPopupComponent,
      {
        injector: this.injector,
      }
    );

    if (!customElements.get('trains-map-marker-popup')) {
      customElements.define(
        'trains-map-marker-popup',
        mapMarkerPopupCustomElement
      );
    }
  }

  public static forRoot(): ModuleWithProviders<SharedComponentsModule> {
    return {
      ngModule: SharedComponentsModule,
      providers: [ConfirmationService, MessageService],
    };
  }
}
