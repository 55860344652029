import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of, tap, timer } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';

import {
  acceptMaintenanceBannerAction,
  hideMaintenanceBannerAction,
  loadPlannedMaintenance,
  loadPlannedMaintenanceFail,
  loadPlannedMaintenanceSuccess,
  showMaintenanceBannerAction,
} from './planned-maintenance.action';
import { PlannedMaintenanceService } from './planned-maintenance.service';

@Injectable()
export class MaintenanceBannerEffects {
  public loadNextPlannedMaintenance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPlannedMaintenance),
      switchMap(({ intervalInSeconds }) =>
        timer(0, intervalInSeconds * 1000).pipe(
          exhaustMap(() =>
            this.plannedMaintenanceService.getNextPlannedMaintenance().pipe(
              map(plannedMaintenance =>
                loadPlannedMaintenanceSuccess({
                  plannedMaintenance: plannedMaintenance || null,
                })
              ),
              catchError(error => of(loadPlannedMaintenanceFail({ error })))
            )
          )
        )
      )
    )
  );

  public togglePlannedMaintenanceBanner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPlannedMaintenanceSuccess),
      map(({ plannedMaintenance }) => {
        if (!plannedMaintenance) {
          return hideMaintenanceBannerAction();
        }

        if (
          this.plannedMaintenanceService.checkPlannedMaintenanceAcceptance(
            plannedMaintenance
          )
        ) {
          return hideMaintenanceBannerAction();
        }

        return showMaintenanceBannerAction();
      })
    )
  );

  public acceptPlannedMaintenance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(acceptMaintenanceBannerAction),
      tap(({ plannedMaintenance }) =>
        this.plannedMaintenanceService.acceptPlannedMaintenance(
          plannedMaintenance
        )
      ),
      map(() => hideMaintenanceBannerAction())
    )
  );

  constructor(
    private actions$: Actions,
    private plannedMaintenanceService: PlannedMaintenanceService
  ) {}
}
