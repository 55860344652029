<div
  class="message"
  [ngClass]="{ 'alert-error': isError, 'alert-warning': isDisabled }"
>
  <i
    class="t-icon"
    aria-hidden="true"
    [ngClass]="{
      't-icon-alert-triangle': isError,
      't-icon-alert-circle': isDisabled
    }"
  ></i>
  <ng-content></ng-content>
</div>
