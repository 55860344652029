import { Component, Input } from '@angular/core';

import { ITrain, TrainTripStatus } from '@base_app/shared/model/train.model';

@Component({
  selector: 'tr-trains-map-marker-popup',
  templateUrl: './trains-map-marker-popup.component.html',
  styleUrls: ['./trains-map-marker-popup.component.scss'],
})
export class TrainsMapMarkerPopupComponent {
  @Input() public train?: ITrain;

  public trainTripStatus = TrainTripStatus;

  constructor() {}
}
