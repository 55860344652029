import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { KeycloakService } from 'keycloak-angular';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

import { IUserKeycloakRealm } from '../security.model';
import { keyCloakInitializer } from './keycloak.initializer';

export const keycloakMultiTenantInitializer =
  (
    http: HttpClient,
    router: Router,
    keycloak: KeycloakService,
    windowService: Window
  ): (() => Promise<unknown>) =>
  (): Promise<unknown> => {
    if (windowService.location.hostname === environment.uiBaseDomain) {
      return keyCloakInitializer(keycloak)();
    }

    return new Promise(async resolve => {
      const tenantName = windowService.location.hostname.replace(
        `.${environment.uiBaseDomain}`,
        ''
      );

      try {
        const data = await lastValueFrom(
          http.get<IUserKeycloakRealm>(
            `${environment.api}/access-manager/v1/api/access-manager/configuration/realm/${tenantName}`
          )
        );

        environment.keycloak.realm = data.realmId;

        await keyCloakInitializer(keycloak)();

        resolve(undefined);
      } catch (error) {
        resolve(undefined);

        router.navigate(['./wrong-realm'], { replaceUrl: true });
      }
    });
  };
