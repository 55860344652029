import { NavigationExtras, Params } from '@angular/router';

import { createAction, props } from '@ngrx/store';

export const go = createAction(
  '[Routing] Go',
  props<{
    path: string[];
    queryParams?: Params | null;
    extras?: NavigationExtras;
  }>()
);

export const back = createAction('[Routing] Back');

export const forward = createAction('[Routing] Forward');
