import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { NgxPermissionsService } from 'ngx-permissions';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { SecurityService } from '../../security.service';
import {
  loadFeatureFlagsStatuses,
  loadFeatureFlagsStatusesFail,
  loadFeatureFlagsStatusesSuccess,
  setFeatureFlagStatus,
} from './feature-flags-statuses.actions';

@Injectable()
export class FeatureFlagsStatusesEffects {
  public loadFeatureFlagsStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFeatureFlagsStatuses),
      switchMap(({ operatorId }) =>
        this.securityService.getFeatureFlagsStatuses(operatorId).pipe(
          map(featureFlagsStatuses =>
            loadFeatureFlagsStatusesSuccess({
              featureFlagsStatuses,
            })
          ),
          catchError(error => of(loadFeatureFlagsStatusesFail({ error })))
        )
      )
    )
  );

  public enableFeatureFlag$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setFeatureFlagStatus),
        tap(({ featureFlagKey, enabled }) => {
          if (enabled) {
            this.ngxPermissionsService.addPermission(featureFlagKey);
          } else {
            this.ngxPermissionsService.removePermission(featureFlagKey);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private securityService: SecurityService,
    private ngxPermissionsService: NgxPermissionsService
  ) {}
}
