import { IStateWithAsync } from '@base_app/shared/model/ngrx.model';

export interface ILoadedLang {
  readonly partials: string[];
}
export interface ILoadedLangs {
  readonly [langKey: string]: ILoadedLang;
}

export interface II18nState extends Readonly<IStateWithAsync> {
  readonly loadedLangs: ILoadedLangs;
  readonly currentLang: string;
  readonly requiredPartials: Array<string>;
}

export const I18N_INITIAL_STATE: II18nState = {
  loadedLangs: {},
  currentLang: 'en',
  requiredPartials: [],
  pending: false,
  resolved: false,
  error: null,
};
