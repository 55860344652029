import { Component, Input } from '@angular/core';

@Component({
  selector: 'tr-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss'],
})
export class StatusIndicatorComponent {
  @Input() public isArchived = false;
  @Input() public isReleased = false;
}
