import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IStateLoaderSpinnerState } from './state-loader-spinner.state';

export const getLoaderSpinnerState =
  createFeatureSelector<IStateLoaderSpinnerState>('stateLoaderSpinner');

export const getShowLoaderSpinner = createSelector(
  getLoaderSpinnerState,
  state => state && state.showSpinner
);
