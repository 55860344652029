import packageInfo from '../../package.json';
import { IEnvironment, KeycloakUrl } from './environment.model';

export const environmentCommon: IEnvironment = {
  appName: 'LiveCoM Suite',
  production: false,
  keycloak: {
    realm: 'isync2',
    url: KeycloakUrl.DEV,
    clientId: 'isync2_ui_dev',
  },
  api: 'backend',
  version: packageInfo.version,
  articleNumber: '33.96.8150',
  documentationUrl: 'https://docs.dev.televic-rail-demo.com',
  documentationArticleNumber: '33.96.8280',
  supportEmail: 'customerservice_TRA@televic.com',
};
