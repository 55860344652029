import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Message, MessageService } from 'primeng/api';

import { IToastMessage } from './toaster.service.model';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  private readonly life = 6000;

  constructor(
    private messageService: MessageService,
    public translate: TranslateService
  ) {}

  public showToast(
    type: string,
    message: IToastMessage,
    title: string,
    life: number = this.life
  ): void {
    const messageObject: Message = {
      severity: type,
      detail: this.translate.instant(message.message, message.params),
      life,
    };

    if (title) {
      messageObject.summary = this.translate.instant(title);
    }

    this.messageService.add(messageObject);
  }

  public showSuccess(
    message: IToastMessage,
    title: string = 'global.toasterMsg.successTitle',
    life: number = this.life
  ): void {
    this.showToast('success', message, title, life);
  }

  public showError(
    message: IToastMessage,
    title: string = 'global.toasterMsg.errorTitle',
    life: number = this.life
  ): void {
    this.showToast('error', message, title, life);
  }

  public showWarning(
    message: IToastMessage,
    title: string = 'global.toasterMsg.warningTitle',
    life: number = this.life
  ): void {
    this.showToast('warn', message, title, life);
  }

  public showInfo(
    message: IToastMessage,
    title: string = '',
    life: number = this.life
  ): void {
    this.showToast('info', message, title, life);
  }
}
