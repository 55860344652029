import {
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { isNotNullOrUndefined } from '@base_app/shared/utils/rxjs.util';

import { cloneDeep } from 'lodash-es';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { FilterPanelService } from '../../filter-panel/filter-panel.service';

@Component({
  selector: 'tr-autocomplete-dropdown-filter',
  templateUrl: './autocomplete-dropdown-filter.component.html',
  styleUrls: ['./autocomplete-dropdown-filter.component.scss'],
})
export class AutocompleteDropdownFilterComponent implements OnInit, OnDestroy {
  @Input() public styleClass = '';
  @Input() public showCaption = true;
  @Input() public caption = 'caption';
  @Input() public placeholder = 'placeholder';
  @Input() public fieldToDisplay = 'name';
  @Input() public propNamesToFilterBy: string[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public itemsForAutocomplete: any[] | null = null;
  @Input() public shortenSelectedTo: number | null = null;
  @Input()
  @HostBinding('class.tr-autocomplete-dropdown-filter-appended')
  public appendTo = 'body';
  @Input() public filterPropName = 'filterPropName';
  @Input() public idsOnlyMode = true;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public selectedItems!: any[];

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private filterPanelService: FilterPanelService) {}

  public ngOnInit(): void {
    if (this.propNamesToFilterBy.length === 0) {
      this.propNamesToFilterBy.push(this.fieldToDisplay);
    }

    this.filterPanelService
      .getFilters()
      .pipe(
        isNotNullOrUndefined(),
        filter(filters => !filters[this.filterPropName]),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(() => (this.selectedItems = []));
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public updateFilters(items: any[]): void {
    const filterValue = this.idsOnlyMode
      ? items.map(item => item.id)
      : cloneDeep(items);

    this.filterPanelService.setFilterProp(this.filterPropName, filterValue);
  }
}
