import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'quote',
})
export class QuotePipe implements PipeTransform {
  public transform(value: string): string {
    return `"${value}"`;
  }
}
