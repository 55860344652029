export enum Status {
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  CANCELLED = 'CANCELLED',
  CANCEL_PENDING = 'CANCEL_PENDING',
  WAITING_FOR_TRIGGER = 'WAITING_FOR_TRIGGER',
  CANCELLED_MANUALLY_ON_BOARD = 'CANCELLED_MANUALLY_ON_BOARD',
  OVERRIDDEN = 'OVERRIDDEN',

  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',

  SENT = 'SENT',
  NO_QUEUE_FOUND = 'NO_QUEUE_FOUND',
  DELIVERED = 'DELIVERED',
  FILE_DOWNLOADED = 'FILE_DOWNLOADED',
  PROCESSED = 'PROCESSED',
  FAILED_DOWNLOAD = 'FAILED_DOWNLOAD',
  FAILED_UPLOAD = 'FAILED_UPLOAD',
  CANCELED = 'CANCELED',

  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
  ENDED = 'ENDED',
  OUT_OF_SERVICE = 'OUT_OF_SERVICE',

  PROCESSING = 'PROCESSING',
  READY = 'READY',
  CREATING_SNAPSHOT = 'CREATING_SNAPSHOT',

  CREATING_DOSSIER = 'CREATING_DOSSIER',
  DELETING_DOSSIER = 'DELETING_DOSSIER',
  COPYING_DOSSIER = 'COPYING_DOSSIER',
  IMPORTING_GTFS = 'IMPORTING_GTFS',
  IMPORTING_GTFS_STOPS = 'IMPORTING_GTFS_STOPS',

  OFFLINE = 'OFFLINE',
  OK = 'OK',
  UNKNOWN = 'UNKNOWN',
  WARNING = 'WARNING',
}

export type TagKind =
  | 'grey'
  | 'yellow'
  | 'green'
  | 'red'
  | 'orange'
  | 'blue'
  | 'lightgrey';

export interface IStatusTagConfig {
  kind: TagKind;
  label: string;
}

export type StatusTagConfig = {
  readonly [key in Status]: IStatusTagConfig;
};

export const statusTagConfigs: Readonly<StatusTagConfig> = {
  [Status.CREATED]: {
    kind: 'yellow',
    label: 'global.status.created',
  },
  [Status.PENDING]: {
    kind: 'blue',
    label: 'global.status.pending',
  },
  [Status.COMPLETED]: {
    kind: 'green',
    label: 'global.status.completed',
  },
  [Status.ERROR]: {
    kind: 'red',
    label: 'global.status.error',
  },
  [Status.CANCELLED]: {
    kind: 'orange',
    label: 'global.status.cancelled',
  },
  [Status.CANCEL_PENDING]: {
    kind: 'blue',
    label: 'global.status.cancelPending',
  },
  [Status.WAITING_FOR_TRIGGER]: {
    kind: 'blue',
    label: 'global.status.waitingForTrigger',
  },
  [Status.CANCELLED_MANUALLY_ON_BOARD]: {
    kind: 'orange',
    label: 'global.status.cancelledManuallyOnBoard',
  },
  [Status.OVERRIDDEN]: {
    kind: 'orange',
    label: 'global.status.overridden',
  },

  [Status.ENABLED]: {
    kind: 'green',
    label: 'global.status.enabled',
  },
  [Status.DISABLED]: {
    kind: 'grey',
    label: 'global.status.disabled',
  },

  [Status.SENT]: {
    kind: 'blue',
    label: 'global.status.sent',
  },
  [Status.NO_QUEUE_FOUND]: {
    kind: 'red',
    label: 'global.status.noQueueFound',
  },
  [Status.DELIVERED]: {
    kind: 'blue',
    label: 'global.status.delivered',
  },
  [Status.FILE_DOWNLOADED]: {
    kind: 'blue',
    label: 'global.status.fileDownloaded',
  },
  [Status.PROCESSED]: {
    kind: 'green',
    label: 'global.status.processed',
  },
  [Status.FAILED_DOWNLOAD]: {
    kind: 'red',
    label: 'global.status.failedDownload',
  },
  [Status.FAILED_UPLOAD]: {
    kind: 'red',
    label: 'global.status.failedUpload',
  },
  [Status.CANCELED]: {
    kind: 'orange',
    label: 'global.status.cancelled',
  },

  [Status.INACTIVE]: {
    kind: 'grey',
    label: 'global.status.inactive',
  },
  [Status.ACTIVE]: {
    kind: 'blue',
    label: 'global.status.active',
  },
  [Status.FINISHED]: {
    kind: 'green',
    label: 'global.status.finished',
  },
  [Status.ENDED]: {
    kind: 'orange',
    label: 'global.status.ended',
  },
  [Status.OUT_OF_SERVICE]: {
    kind: 'red',
    label: 'global.status.outOfService',
  },

  [Status.PROCESSING]: {
    kind: 'blue',
    label: 'global.status.processing',
  },
  [Status.READY]: {
    kind: 'green',
    label: 'global.status.ready',
  },
  [Status.CREATING_SNAPSHOT]: {
    kind: 'orange',
    label: 'global.status.creatingSnapshot',
  },
  [Status.COPYING_DOSSIER]: {
    kind: 'yellow',
    label: 'global.status.copyingDossier',
  },
  [Status.IMPORTING_GTFS]: {
    kind: 'yellow',
    label: 'global.status.importingGtfs',
  },
  [Status.IMPORTING_GTFS_STOPS]: {
    kind: 'yellow',
    label: 'global.status.importingGtfsStops',
  },
  [Status.CREATING_DOSSIER]: {
    kind: 'orange',
    label: 'global.status.creatingDossier',
  },
  [Status.DELETING_DOSSIER]: {
    kind: 'blue',
    label: 'global.status.deletingDossier',
  },

  [Status.OFFLINE]: {
    kind: 'lightgrey',
    label: 'global.status.offline',
  },

  [Status.OK]: {
    kind: 'green',
    label: 'global.status.ok',
  },
  [Status.WARNING]: {
    kind: 'yellow',
    label: 'global.status.warning',
  },
  [Status.UNKNOWN]: {
    kind: 'lightgrey',
    label: 'global.status.unknown',
  },
};
