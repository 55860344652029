import { INavLink } from '@base_app/shared/interfaces/nav-link';
import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IDataAndParentsData, RouterStateUrl } from '../routing.model';

export const getRoutingState =
  createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');

export const getRoutingStateState = createSelector(
  getRoutingState,
  router => router?.state || null
);

export const getCurrentStateParams = createSelector(
  getRoutingStateState,
  state => state?.params || null
);

export const getCurrentStateUrl = createSelector(
  getRoutingStateState,
  state => state?.url || null
);

export const getCurrentStateUrlWithoutQueryParams = createSelector(
  getCurrentStateUrl,
  url => url?.split('?')[0] || null
);

export const getCurrentStateQueryParams = createSelector(
  getRoutingStateState,
  state => state?.queryParams || null
);

export const getCurrentStateDataParams = createSelector(
  getRoutingStateState,
  state => state?.data || null
);

export const getCurrentStateParentsDataParams = createSelector(
  getRoutingStateState,
  state => state?.parentsData || null
);

export const getCurrentStateDataParamsAndParentsDataParams = createSelector(
  getCurrentStateDataParams,
  getCurrentStateParentsDataParams,
  (data, parentsData) => {
    if (!data && !parentsData) {
      return null;
    }

    return {
      data: { ...data },
      parentsData: { ...parentsData },
    } as IDataAndParentsData;
  }
);

export const getCurrentStateRequiredFunctionalitiesIds = createSelector(
  getCurrentStateDataParamsAndParentsDataParams,
  dataAndParentsData => {
    if (!dataAndParentsData) {
      return null;
    }

    const currentStateRequiredFunctionalityId =
      dataAndParentsData.data?.['functionalityId'];
    const parentRequiredFunctionalityId =
      dataAndParentsData.parentsData?.['functionalityId'];
    const requiredFunctionalityIds: number[] = [];

    if (currentStateRequiredFunctionalityId) {
      requiredFunctionalityIds.push(currentStateRequiredFunctionalityId);
    }

    if (parentRequiredFunctionalityId) {
      requiredFunctionalityIds.push(parentRequiredFunctionalityId);
    }

    if (!requiredFunctionalityIds.length) {
      return null;
    }

    return requiredFunctionalityIds;
  }
);

export const getPageTitle = createSelector(
  getCurrentStateDataParamsAndParentsDataParams,
  dataAndParentsData =>
    (dataAndParentsData?.data['pageTitle'] as string) ||
    (dataAndParentsData?.parentsData['pageTitle'] as string) ||
    null
);

export const getFunctionalityId = createSelector(
  getCurrentStateDataParamsAndParentsDataParams,
  dataAndParentsData => {
    if (!dataAndParentsData) {
      return null;
    }

    const currentStateFunctionalityId = dataAndParentsData.data?.[
      'functionalityId'
    ] as number;
    const parentFunctionalityId = dataAndParentsData.parentsData?.[
      'functionalityId'
    ] as number;

    return currentStateFunctionalityId || parentFunctionalityId || null;
  }
);

export const getNavigationItems = createSelector(
  getCurrentStateDataParamsAndParentsDataParams,
  dataAndParentsData => {
    if (!dataAndParentsData) {
      return null;
    }

    const currentStateNavigationItems = dataAndParentsData.data?.[
      'navigationItems'
    ] as INavLink[];
    const parentNavigationItems = dataAndParentsData.parentsData?.[
      'navigationItems'
    ] as INavLink[];

    return currentStateNavigationItems || parentNavigationItems || null;
  }
);

export const getStateParamSelector = (stateParamName: string) =>
  createSelector(
    getCurrentStateParams,
    stateParams => (stateParams?.[stateParamName] as string) || null
  );

export const getPathStateParamSelectorForBreadcrumbs = (
  stateParamName: string
) =>
  createSelector(getCurrentStateParams, stateParams => {
    const pathStateParam: string = stateParams?.[stateParamName];

    if (!pathStateParam) {
      return null;
    }

    return pathStateParam.replace(/\//g, ' / ');
  });
