import { Component, Input, OnChanges, OnDestroy } from '@angular/core';

import { SimpleChangesTyped } from '@base_app/shared/utils/angular.utils';

@Component({
  selector: 'tr-audio-file-player',
  templateUrl: './audio-file-player.component.html',
  styleUrls: ['./audio-file-player.component.scss'],
})
export class AudioFilePlayerComponent implements OnChanges, OnDestroy {
  @Input() public audioFile?: File;

  public audioUrl: string | null = null;

  constructor() {}

  public ngOnChanges({
    audioFile,
  }: SimpleChangesTyped<AudioFilePlayerComponent>): void {
    if (!audioFile) {
      return;
    }

    if (audioFile.currentValue) {
      if (this.audioUrl) {
        URL.revokeObjectURL(this.audioUrl);
      }

      this.audioUrl = URL.createObjectURL(this.audioFile as File);
    } else {
      if (this.audioUrl) {
        URL.revokeObjectURL(this.audioUrl);
      }

      this.audioUrl = null;
    }
  }

  public ngOnDestroy(): void {
    if (this.audioUrl) {
      URL.revokeObjectURL(this.audioUrl);
    }
  }
}
