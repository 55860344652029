// eslint-disable-next-line @typescript-eslint/ban-types
export class SelectItem<T = object | string> {
  public label: string;
  public value: T;

  constructor(value: T, label?: string) {
    this.label = label || (value as unknown as string);
    this.value = value;
  }
}
