export enum FilterComparisonType {
  EQUAL_TO = 'EQUAL_TO',
  LARGE_THAN = 'LARGE_THAN',
  LESS_THAN = 'LESS_THAN',
}

export interface IFilterRelationConfig<T> {
  value: T;
  label: string;
}

export type FilterRelationConfigs = {
  [key in FilterComparisonType]: IFilterRelationConfig<key>;
};

export class FilterRelationConstants {
  private static filterRelations: Readonly<FilterRelationConfigs> = {
    [FilterComparisonType.EQUAL_TO]: {
      value: FilterComparisonType.EQUAL_TO,
      label: 'global.filters.filterRelation.values.equal',
    },
    [FilterComparisonType.LARGE_THAN]: {
      value: FilterComparisonType.LARGE_THAN,
      label: 'global.filters.filterRelation.values.larger',
    },
    [FilterComparisonType.LESS_THAN]: {
      value: FilterComparisonType.LESS_THAN,
      label: 'global.filters.filterRelation.values.less',
    },
  };

  public static getFilterRelations(): Readonly<
    IFilterRelationConfig<FilterComparisonType>
  >[] {
    return Object.values(this.filterRelations);
  }

  public static getFilterRelation(
    type: FilterComparisonType
  ): Readonly<IFilterRelationConfig<FilterComparisonType>> {
    return { ...this.filterRelations[type] };
  }
}
