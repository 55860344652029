import { cloneDeep } from 'lodash-es';

export const moveInArray = <T>(arr: T[], from: number, to: number): T[] => {
  const arrayCopy: T[] = cloneDeep(arr);
  const item = arrayCopy.splice(from, 1);

  arrayCopy.splice(to, 0, item[0]);

  return arrayCopy;
};

export const findAsyncSequentialInArray = async <T>(
  array: T[] | Readonly<T[]> | undefined,
  predicate: (t: T) => Promise<boolean>
): Promise<T | undefined> => {
  if (!array) {
    return undefined;
  }

  for (const t of array) {
    if (await predicate(t)) {
      return t;
    }
  }

  return undefined;
};

export const asyncSome = async <T>(
  arr: T[],
  predicate: (value: T) => unknown
): Promise<boolean> => {
  for (let e of arr) {
    if (await predicate(e)) return true;
  }

  return false;
};
