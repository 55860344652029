import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FilterPanelService } from './filter-panel.service';

@Component({
  selector: 'tr-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
  providers: [FilterPanelService],
})
export class FilterPanelComponent implements OnInit, OnDestroy {
  @Input() public showPanel = true;
  @Input() public showTitle = false;
  @Input() public showCloseIcon = true;
  @HostBinding('class.tr-filter-panel-with-clear')
  @Input()
  public showClearAll = false;
  @Input() public styleClass = '';

  @Output() public showPanelChange = new EventEmitter();
  @Output() public filtersChanged = new EventEmitter();

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private filterPanelService: FilterPanelService) {}

  public ngOnInit(): void {
    this.filterPanelService
      .getFilters()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(filters => this.filtersChanged.emit(filters));
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  public clearFilters(): void {
    this.filterPanelService.clearFilters();
  }

  public close(): void {
    this.showPanel = false;
    this.showPanelChange.emit(this.showPanel);
  }
}
