<div
  class="trains-map-marker-popup-title"
  *ngIf="train && train.vehicles.length > 1"
>
  {{ 'global.trainsMap.popup.title' | translate }}
</div>

<div
  class="t-widget"
  *ngFor="let vehicle of train?.vehicles"
>
  <strong
    >{{ vehicle.vehicleSerialNumber }}
    <span
      class="text-break"
      *ngIf="
        vehicle.trip?.status?.id === trainTripStatus.INACTIVE || !vehicle.trip;
        else tripNameOrIdContent
      "
    >
      ({{ 'global.trainsMap.popup.message.noTrip' | translate }})
    </span>
    <ng-template #tripNameOrIdContent>
      <span class="text-break">
        ({{
          vehicle.trip.tripName ? vehicle.trip.tripName : vehicle.trip.tripId
        }})
      </span>
    </ng-template>
  </strong>

  <div
    class="trains-map-marker-popup-date"
    *ngIf="vehicle.trip; else lastUpdatedFromLocation"
  >
    {{ 'global.trainsMap.popup.message.lastUpdated' | translate }}
    {{ vehicle.trip.collectedAt | date: 'medium' }}
  </div>

  <ng-template #lastUpdatedFromLocation>
    <div
      class="trains-map-marker-popup-date"
      *ngIf="vehicle?.location?.collectedAt"
    >
      {{ 'global.trainsMap.popup.message.lastUpdated' | translate }}
      {{ vehicle.location.collectedAt | date: 'medium' }}
    </div>
  </ng-template>

  <div>
    <tr-status-tag [status]="$any(vehicle.status)"></tr-status-tag>
  </div>
</div>
