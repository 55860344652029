import { createReducer, on } from '@ngrx/store';

import {
  hideMaintenanceBannerAction,
  loadPlannedMaintenance,
  loadPlannedMaintenanceFail,
  loadPlannedMaintenanceSuccess,
  showMaintenanceBannerAction,
} from './planned-maintenance.action';
import { IPlannedMaintenanceState } from './planned-maintenance.model';

export const plannedMaintenanceInitialState: IPlannedMaintenanceState = {
  showBanner: false,
  plannedMaintenance: null,
  pending: false,
  resolved: false,
  error: null,
};

export const plannedMaintenanceReducer = createReducer(
  plannedMaintenanceInitialState,

  on(loadPlannedMaintenance, () => ({
    ...plannedMaintenanceInitialState,
    pending: true,
  })),

  on(loadPlannedMaintenanceSuccess, (state, { plannedMaintenance }) => ({
    ...state,
    pending: false,
    resolved: true,
    plannedMaintenance,
  })),

  on(loadPlannedMaintenanceFail, (state, { error }) => ({
    ...state,
    pending: false,
    resolved: true,
    error,
  })),

  on(showMaintenanceBannerAction, state => ({
    ...state,
    showBanner: true,
  })),

  on(hideMaintenanceBannerAction, state => ({
    ...state,
    showBanner: false,
  }))
);
