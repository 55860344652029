import { IDropdownWithTranslationsConfig } from './dropdown.model';

export enum TrainTripStatus {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
  CANCELLED = 'CANCELLED',
}

export enum TrainVehicleStatus {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
  CANCELLED = 'CANCELLED',
  OFFLINE = 'OFFLINE',
  OUT_OF_SERVICE = 'OUT_OF_SERVICE',
}

export enum TrainLocationStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
}

export interface ITrainTripStatus {
  id: TrainTripStatus;
  name: string;
}

export interface ITrainTripDirection {
  id: string;
  name: string;
}

export interface ITrainLocation {
  latitude: number;
  longitude: number;
  altitude: number;
  status: TrainLocationStatus;
  collectedAt: number;
}

export interface ITrainTrip {
  tripId: string;
  tripName: string;
  direction: ITrainTripDirection;
  status: ITrainTripStatus;
  collectedAt: number;
}

export interface ITrainVehicle {
  vehicleId: number;
  vehicleSerialNumber: string;
  location: ITrainLocation;
  trip: ITrainTrip;
  status: TrainVehicleStatus;
}

export interface ITrain {
  vehicles: ITrainVehicle[];
  location: ITrainLocation;
  // TODO: trains doesn't have actual ids on backend, as it's just
  // vehicles grouped by trip
  // so tripId (of first vehicle) should be used instead for now
  fakeId: string;
}

export const TRAIN_VEHICLE_SERVICE_STATUS_CONFIG: Readonly<{
  [key in TrainVehicleStatus]: IDropdownWithTranslationsConfig<key>;
}> = {
  [TrainVehicleStatus.ACTIVE]: {
    value: TrainVehicleStatus.ACTIVE,
    label: 'global.status.active',
  },
  [TrainVehicleStatus.INACTIVE]: {
    value: TrainVehicleStatus.INACTIVE,
    label: 'global.status.inactive',
  },
  [TrainVehicleStatus.FINISHED]: {
    value: TrainVehicleStatus.FINISHED,
    label: 'global.status.finished',
  },
  [TrainVehicleStatus.CANCELLED]: {
    value: TrainVehicleStatus.CANCELLED,
    label: 'global.status.cancelled',
  },
  [TrainVehicleStatus.OFFLINE]: {
    value: TrainVehicleStatus.OFFLINE,
    label: 'global.status.offline',
  },
  [TrainVehicleStatus.OUT_OF_SERVICE]: {
    value: TrainVehicleStatus.OUT_OF_SERVICE,
    label: 'global.status.outOfService',
  },
};
