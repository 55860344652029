import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { asyncSome } from '@base_app/shared/utils/array.utils';

import { NgxPermissionsService } from 'ngx-permissions';

@Directive({
  selector: '[trNgxPermissionsOnlyAnd]',
})
export class NgxPermissionsOnlyAndDirective implements OnInit {
  private permissionsToCheck: string[] = [];
  private isHidden = true;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private permissionsService: NgxPermissionsService
  ) {}

  @Input()
  public set trNgxPermissionsOnlyAnd(permissionsToCheck: string[]) {
    this.permissionsToCheck = permissionsToCheck;
    this.updateView();
  }

  public ngOnInit(): void {
    this.permissionsService.permissions$.subscribe(() => this.updateView());
  }

  private async updateView(): Promise<void> {
    if (await this.hasPermissions()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private async hasPermissions(): Promise<boolean> {
    return !(await asyncSome(this.permissionsToCheck, async permissionToCheck =>
      this.permissionsService
        .hasPermission(permissionToCheck)
        .then(result => !result)
    ));
  }
}
