import { createReducer, on } from '@ngrx/store';

import {
  loadEnabledReleaseFeatureFlags,
  loadEnabledReleaseFeatureFlagsFail,
  loadEnabledReleaseFeatureFlagsSuccess,
} from './enabled-release-feature-flags.actions';
import { EnabledReleaseFeatureFlagsState } from './enabled-release-feature-flags.model';

export const enabledReleaseFeatureFlagsInitialState: EnabledReleaseFeatureFlagsState =
  {
    pending: false,
    resolved: false,
    error: null,
    enabledReleaseFeatureFlags: null,
  };

export const enabledReleaseFeatureFlagsReducer = createReducer(
  enabledReleaseFeatureFlagsInitialState,

  on(loadEnabledReleaseFeatureFlags, () => ({
    ...enabledReleaseFeatureFlagsInitialState,
    pending: true,
  })),

  on(
    loadEnabledReleaseFeatureFlagsSuccess,
    (state, { enabledReleaseFeatureFlags }) => ({
      ...state,
      enabledReleaseFeatureFlags: [...enabledReleaseFeatureFlags],
      pending: false,
      resolved: true,
      error: null,
    })
  ),

  on(loadEnabledReleaseFeatureFlagsFail, (_state, { error }) => ({
    ...enabledReleaseFeatureFlagsInitialState,
    pending: false,
    resolved: true,
    error,
  }))
);
