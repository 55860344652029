<router-outlet></router-outlet>

<tr-state-loader-spinner></tr-state-loader-spinner>

<p-toast position="bottom-right"></p-toast>

<p-confirmDialog
  class="tr-confirmation-dialog"
  header="Confirmation"
  icon="t-icon t-icon-alert-triangle"
  [style]="{ width: '500px' }"
>
</p-confirmDialog>

<tr-planned-maintenance-banner></tr-planned-maintenance-banner>
