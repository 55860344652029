import { IStateWithAsync } from '@base_app/shared/model/ngrx.model';

export interface IUserOperatorState extends Readonly<IStateWithAsync> {
  operatorId?: Readonly<number> | null;
}

export const userOperatorInitialState: IUserOperatorState = {
  pending: false,
  resolved: false,
  error: null,
  operatorId: null,
};
