import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'tr-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @HostBinding('class.preloaderparent') public preloaderparent = true;
  @HostBinding('class.fillspace') @Input() public fillspace = true;
}
