import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { loadPlannedMaintenance } from './core/planned-maintenance';

@Component({
  selector: 'tr-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private store$: Store) {}

  public ngOnInit(): void {
    this.store$.dispatch(loadPlannedMaintenance({ intervalInSeconds: 60 }));
  }
}
