import { Inject, Injectable } from '@angular/core';

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { WINDOW } from '@base_app/core/services/window.service';

@Injectable()
export class MaintenanceInterceptor implements HttpInterceptor {
  constructor(@Inject(WINDOW) private window: Window) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (error.status === 503) {
          this.window.location.reload();
        }

        return throwError(() => error);
      })
    );
  }
}
