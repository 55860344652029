import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'tr-row-card-with-actions',
  templateUrl: './row-card-with-actions.component.html',
  styleUrls: ['./row-card-with-actions.component.scss'],
})
export class RowCardWithActionsComponent {
  @HostBinding('class.with-action') @Input() public withAction = true;

  @Input() public label = '';
  @Input() public hasNesting = true;
  @Input() public showActions = true;
  @Input() public showEdit = true;
  @Input() public showView = false;
  @Input() public showArchive = true;
  @Input() public showDelete = false;

  @Output() public archive: EventEmitter<boolean> = new EventEmitter();
  @Output() public delete: EventEmitter<void> = new EventEmitter();

  @Output() public edit: EventEmitter<void> = new EventEmitter();
  @Output() public view: EventEmitter<void> = new EventEmitter();

  public editClicked(event: Event): void {
    event.stopPropagation();
    this.edit.emit();
  }

  public viewClicked(event: Event): void {
    event.stopPropagation();
    this.view.emit();
  }
}
