import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Data,
  RouterStateSnapshot,
} from '@angular/router';

import { RouterStateSerializer } from '@ngrx/router-store';

import { RouterStateUrl } from './routing.model';

@Injectable()
export class RoutingCustomSerializer
  implements RouterStateSerializer<RouterStateUrl>
{
  public serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route: ActivatedRouteSnapshot = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams },
    } = routerState;

    const { params, data } = route;

    const parentsData = this.getRouteParentsData(route);

    return { url, params, queryParams, data, parentsData };
  }

  public getRouteParentsData(
    routeToGetParentData: ActivatedRouteSnapshot
  ): Data {
    if (!routeToGetParentData.parent) {
      return {} as Data;
    }

    const parentsParentData = this.getRouteParentsData(
      routeToGetParentData.parent
    );

    return {
      ...routeToGetParentData.data,
      ...parentsParentData,
    };
  }
}
