import { Component, Input } from '@angular/core';

@Component({
  selector: 'tr-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss'],
})
export class FormGroupComponent {
  @Input() public title = '';
  @Input() public titleHighlighted = true;
  @Input() public withLink = false;
  @Input() public isRequired = false;

  constructor() {}
}
