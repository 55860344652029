import { Component, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivateFn } from '@angular/router';

import { Store } from '@ngrx/store';

import { removeTranslationPartial } from './store/i18n.actions';

export const i18nPartialsDeactivateGuard: CanDeactivateFn<Component> = (
  _component: Component,
  route: ActivatedRouteSnapshot
): boolean => {
  const store$: Store = inject(Store);

  (route.data['i18nPartials'] as string[]).forEach(partialName =>
    store$.dispatch(removeTranslationPartial({ partialName }))
  );

  return true;
};
