import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { PlannedMaintenanceBannerComponent } from './planned-maintenance-banner';
import { plannedMaintenanceReducer } from './store';
import { MaintenanceBannerEffects } from './store/planned-maintenance.effects';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    StoreModule.forFeature('plannedMaintenance', plannedMaintenanceReducer),
    EffectsModule.forFeature([MaintenanceBannerEffects]),
  ],
  declarations: [PlannedMaintenanceBannerComponent],
  exports: [PlannedMaintenanceBannerComponent],
})
export class PlannedMaintenanceModule {}
