<p-dropdown
  [options]="$any(functionalityNames$ | async)"
  [readonly]="readonlyControl"
  [(ngModel)]="value"
  appendTo="body"
  (onChange)="changeValue()"
  [placeholder]="'global.filters.functionalityName.placeholder' | translate"
  optionLabel="label"
>
  <ng-template
    let-item
    pTemplate="selectedItem"
  >
    <span>{{ item.label | translate }}</span>
  </ng-template>

  <ng-template
    let-item
    pTemplate="item"
  >
    <span>{{ item.label | translate }}</span>
  </ng-template>
</p-dropdown>
