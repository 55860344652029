import { KeycloakService } from 'keycloak-angular';

import { environment } from '../../../../environments/environment';

export const keyCloakInitializer =
  (keycloak: KeycloakService): (() => Promise<boolean>) =>
  (): Promise<boolean> =>
    keycloak.init({
      config: environment.keycloak,
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false,
      },
    });
