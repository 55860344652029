import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgxPermissionsModule } from 'ngx-permissions';

import { I18nModule } from './i18n/i18n.module';
import { PageTitleModule } from './page-title/page-title.module';
import { PlannedMaintenanceModule } from './planned-maintenance';
import { RoutingModule } from './routing/routing.module';
import { SecurityModule } from './security/security.module';
import { WINDOW_PROVIDERS } from './services/window.service';
import { StateLoaderSpinnerModule } from './state-loader-spinner';

export const coreModules = [
  I18nModule,
  PlannedMaintenanceModule,
  PageTitleModule,
  RoutingModule,
  SecurityModule,
  StateLoaderSpinnerModule,
];

@NgModule({
  imports: [CommonModule, NgxPermissionsModule.forRoot(), ...coreModules],
  exports: [...coreModules],
  providers: [WINDOW_PROVIDERS],
})
export class CoreModule {}
