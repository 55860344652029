import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { IDropdownWithTranslationsConfig } from '@base_app/shared/model/dropdown.model';
import { isNotNullOrUndefined } from '@base_app/shared/utils/rxjs.util';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { FilterPanelService } from '../../filter-panel/filter-panel.service';

@Component({
  selector: 'tr-dropdown-value',
  templateUrl: './dropdown-value.component.html',
  styleUrls: ['./dropdown-value.component.scss'],
})
export class DropdownValueComponent implements OnInit, OnDestroy {
  @Input() public filterPropName = 'type';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public configs: IDropdownWithTranslationsConfig<any>[] = [];
  @Input() public placeholder = '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public selectedConfigValue: any;

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private filterPanelService: FilterPanelService) {}

  public ngOnInit(): void {
    this.filterPanelService
      .getFilters()
      .pipe(
        isNotNullOrUndefined(),
        filter(filters => !filters[this.filterPropName]),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(() => (this.selectedConfigValue = null));
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  public updateFilters(): void {
    this.filterPanelService.setFilterProp(
      this.filterPropName,
      this.selectedConfigValue
    );
  }
}
