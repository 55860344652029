import { Injectable } from '@angular/core';

import { isNotNullOrUndefined } from '@base_app/shared/utils/rxjs.util';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { combineLatest, of } from 'rxjs';
import {
  catchError,
  delay,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { I18nService } from '../i18n.service';
import {
  getTranslationPartial,
  getTranslationPartialError,
  getTranslationPartialSuccess,
  reloadRequiredPartials,
  reloadRequiredPartialsError,
  reloadRequiredPartialsSuccess,
} from './i18n.actions';
import {
  arePartialsLoadedForCurrentLanguage,
  getCurrentLanguage,
  getRequiredPartialsToRefetch,
} from './i18n.selectors';

@Injectable()
export class I18nEffects {
  public getTranslationPartial$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTranslationPartial),
      map(action => action.partialName),
      switchMap(partialName =>
        of(partialName).pipe(
          withLatestFrom(
            this.store$.pipe(
              select(getCurrentLanguage),
              isNotNullOrUndefined()
            ),
            this.store$.pipe(
              select(arePartialsLoadedForCurrentLanguage([partialName]))
            )
          )
        )
      ),
      mergeMap(([partialName, currentLang, isPartialLoadedForCurrentLang]) => {
        const getTranslationPartialSuccessAction = getTranslationPartialSuccess(
          {
            languageKey: currentLang,
            partialName,
          }
        );

        if (isPartialLoadedForCurrentLang) {
          this.i18nService.useLanguage(currentLang);

          return of(getTranslationPartialSuccessAction).pipe(delay(0));
        }

        return this.i18nService
          .getTranslationPartial(currentLang, partialName)
          .pipe(
            map(translations => {
              this.i18nService.setTranslation(currentLang, translations);
              this.i18nService.useLanguage(currentLang);

              return getTranslationPartialSuccessAction;
            }),
            catchError(error => of(getTranslationPartialError({ error })))
          );
      })
    )
  );

  public reloadRequiredPartials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reloadRequiredPartials),
      withLatestFrom(this.store$.pipe(select(getRequiredPartialsToRefetch))),
      map(([_action, requiredPartialsToRefetch]) => requiredPartialsToRefetch),
      withLatestFrom(
        this.store$.pipe(select(getCurrentLanguage), isNotNullOrUndefined())
      ),
      switchMap(([requiredPartialsToRefetch, currentLanguage]) => {
        const successesAction = reloadRequiredPartialsSuccess({
          languageKey: currentLanguage,
          partialNames: requiredPartialsToRefetch || [],
        });

        if (!requiredPartialsToRefetch) {
          this.i18nService.useLanguage(currentLanguage);

          return of(successesAction);
        }

        return combineLatest(
          requiredPartialsToRefetch.map(partial =>
            this.i18nService
              .getTranslationPartial(currentLanguage, partial)
              .pipe(
                map(translations =>
                  this.i18nService.setTranslation(currentLanguage, translations)
                )
              )
          )
        ).pipe(
          tap(() => this.i18nService.useLanguage(currentLanguage)),
          map(() =>
            reloadRequiredPartialsSuccess({
              languageKey: currentLanguage,
              partialNames: requiredPartialsToRefetch,
            })
          ),
          catchError(error => of(reloadRequiredPartialsError({ error })))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store,
    private i18nService: I18nService
  ) {}
}
