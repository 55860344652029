<div
  class="form-group-title"
  [ngClass]="{
    'form-group-title_highlighted': titleHighlighted,
    'form-group-title_with_link': withLink
  }"
>
  <strong [ngClass]="{ 'required-mark': isRequired }">{{ title }}</strong>
</div>

<div class="form-group-content">
  <ng-content></ng-content>
</div>
