<p-autoComplete
  [(ngModel)]="value"
  [suggestions]="filteredAutocompleteItems"
  [forceSelection]="true"
  (completeMethod)="filterAutocompleteItems($event, propNamesToFilterBy)"
  (onSelect)="selectAutocomplete()"
  (onUnselect)="unselectAutocomplete()"
  [field]="fieldToDisplay"
  [appendTo]="appendTo"
  [placeholder]="placeholder | translate"
  [emptyMessage]="'global.noData' | translate"
  [multiple]="true"
  [dropdown]="true"
  [inputId]="inputId"
>
  <ng-template
    let-item
    pTemplate="item"
  >
    <span>{{ item[fieldToDisplay] }}</span>
  </ng-template>

  <ng-template
    let-selectedItem
    pTemplate="selectedItem"
  >
    <span
      class="tr-autocomplete-pillow"
      [pTooltip]="selectedItem[fieldToDisplay] | translate"
    >
      {{
        shortenSelectedTo
          ? (selectedItem[fieldToDisplay] | shorten: shortenSelectedTo : '...')
          : selectedItem[fieldToDisplay]
      }}
    </span>
  </ng-template>
</p-autoComplete>
