import { isPlatformBrowser } from '@angular/common';
import {
  ClassProvider,
  FactoryProvider,
  Injectable,
  InjectionToken,
  PLATFORM_ID,
} from '@angular/core';

export const WINDOW = new InjectionToken('WindowToken');

export abstract class WindowRef {
  // eslint-disable-next-line @typescript-eslint/ban-types
  public get nativeWindow(): Window | object {
    throw new Error('Not implemented.');
  }
}

@Injectable()
export class BrowserWindowRef extends WindowRef {
  constructor() {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public override get nativeWindow(): Window | object {
    return window;
  }
}

export const windowFactory = (
  browserWindowRef: BrowserWindowRef,
  // eslint-disable-next-line @typescript-eslint/ban-types
  platformId: object
  // eslint-disable-next-line @typescript-eslint/ban-types
): Window | object => {
  if (isPlatformBrowser(platformId)) {
    return browserWindowRef.nativeWindow;
  }

  return new Object();
};

const browserWindowProvider: ClassProvider = {
  provide: WindowRef,
  useClass: BrowserWindowRef,
};

const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: windowFactory,
  deps: [WindowRef, PLATFORM_ID],
};

export const WINDOW_PROVIDERS = [browserWindowProvider, windowProvider];
