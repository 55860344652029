import { Component, HostBinding, Input, OnChanges } from '@angular/core';

import { SimpleChangesTyped } from '@base_app/shared/utils/angular.utils';

import { Status, statusTagConfigs, TagKind } from './status.tag.model';

@Component({
  selector: 'tr-status-tag',
  templateUrl: './status-tag.component.html',
  styleUrls: ['./status-tag.component.scss'],
})
export class StatusTagComponent implements OnChanges {
  @HostBinding('class') public hostClasses = '';

  @Input() public status!: Status;
  @Input() public label?: string;

  public tagLabel: string | null = null;

  private readonly baseHostClass = 'tag';
  private readonly defaultTagKindClass: TagKind = 'lightgrey';

  public ngOnChanges(changes: SimpleChangesTyped<StatusTagComponent>): void {
    if (!changes.status) {
      return;
    }

    if (statusTagConfigs[this.status]) {
      this.hostClasses = `${this.baseHostClass} ${
        statusTagConfigs[this.status].kind
      }`;
      this.tagLabel = statusTagConfigs[this.status].label;

      return;
    }

    this.hostClasses = `${this.baseHostClass} ${this.defaultTagKindClass}`;
    this.tagLabel = null;
  }
}
