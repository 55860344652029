import { Component, Inject, OnInit } from '@angular/core';

import {
  reloadRequiredPartials,
  setCurrentLanguageKey,
} from '@base_app/core/i18n/store/i18n.actions';
import { getCurrentLanguage } from '@base_app/core/i18n/store/i18n.selectors';
import { WINDOW } from '@base_app/core/services/window.service';
import { isNotNullOrUndefined } from '@base_app/shared/utils/rxjs.util';
import { select, Store } from '@ngrx/store';

import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';

@Component({
  selector: 'tr-main-navigation-actions',
  templateUrl: './main-navigation-actions.component.html',
  styleUrls: ['./main-navigation-actions.component.scss'],
})
export class MainNavigationActionsComponent implements OnInit {
  public currentLanguage$!: Observable<string>;

  constructor(
    @Inject(WINDOW) private window: Window,
    private keyCloak: KeycloakService,
    private store$: Store
  ) {}

  public ngOnInit(): void {
    this.currentLanguage$ = this.store$.pipe(
      select(getCurrentLanguage),
      isNotNullOrUndefined()
    );
  }

  public logout(): void {
    this.keyCloak.logout(this.window.location.origin);
  }

  public useLanguage(languageKey: string): void {
    this.store$.dispatch(setCurrentLanguageKey({ languageKey }));
    this.store$.dispatch(reloadRequiredPartials());
  }
}
