import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { getCurrentStateUrl } from '@base_app/core/routing/store/routing.selectors';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { filter, first, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { getUserOperator } from '../ngrx/user-operator/user-operator.actions';
import {
  getSecurityUserOperator,
  isSecurityUserOperatorSuccessfullyResolved,
} from '../ngrx/user-operator/user-operator.selectors';

export const securityUserOperatorGuard: CanActivateFn =
  (): Observable<boolean> => {
    const store$: Store = inject(Store);
    const router: Router = inject(Router);

    store$.dispatch(getUserOperator());

    return store$.pipe(
      select(isSecurityUserOperatorSuccessfullyResolved),
      filter(isSuccessfullyResolved => isSuccessfullyResolved),
      switchMap(() =>
        store$.pipe(
          select(getSecurityUserOperator),
          withLatestFrom(store$.select(getCurrentStateUrl))
        )
      ),
      map(([userOperatorId, currentStateUrl]) => {
        if (currentStateUrl === '/operators' && userOperatorId !== null) {
          // navigateByUrl used instead of UrlTree returned by router.parseUrl
          // as it does not support NavigationExtras
          // see https://github.com/angular/angular/issues/27148
          router.navigateByUrl(`/operators/${userOperatorId}/functionality`, {
            replaceUrl: true,
          });

          return false;
        }

        return true;
      }),
      first()
    );
  };
