import { HttpErrorResponse } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';

export const getTranslationPartial = createAction(
  '[I18n] Get Translation Partial',
  props<{ partialName: string }>()
);

export const removeTranslationPartial = createAction(
  '[I18n] Remove Translation Partial',
  props<{ partialName: string }>()
);

export const getTranslationPartialSuccess = createAction(
  '[I18n] Get Translation Partial Success',
  props<{ languageKey: string; partialName: string }>()
);

export const getTranslationPartialError = createAction(
  '[I18n] Get Translation Partial Error',
  props<{ error: HttpErrorResponse }>()
);

export const reloadRequiredPartials = createAction(
  '[I18n] Reload Required Partials'
);

export const reloadRequiredPartialsSuccess = createAction(
  '[I18n] Reload Required Partials Success',
  props<{ languageKey: string; partialNames: string[] }>()
);

export const reloadRequiredPartialsError = createAction(
  '[I18n] Reload Required Partials Error',
  props<{ error: HttpErrorResponse }>()
);

export const setCurrentLanguageKey = createAction(
  '[I18n] Set Current Language Key',
  props<{ languageKey: string }>()
);
