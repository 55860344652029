import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class BlobErrorsInterceptor implements HttpInterceptor {
  constructor() {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error instanceof HttpErrorResponse &&
          error.error instanceof Blob &&
          error.error.type === 'application/json'
        ) {
          // https://github.com/angular/angular/issues/19888
          // When request of type Blob, the error is also in Blob instead of object of the json data
          const reader: FileReader = new FileReader();

          const parsedErrorBlobObservable: Observable<HttpErrorResponse> =
            new Observable(observer => {
              reader.onloadend = () => {
                const parsedError = JSON.parse(reader.result as string);

                observer.error(
                  new HttpErrorResponse({
                    error: parsedError,
                    headers: error.headers,
                    status: error.status,
                    statusText: error.statusText,
                    url: error.url ?? undefined,
                  })
                );

                observer.complete();
              };
            });

          reader.readAsText(error.error);

          return parsedErrorBlobObservable.pipe(
            switchMap(res => throwError(() => res))
          );
        }

        return throwError(() => error);
      })
    );
  }
}
