import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const SECOND_MILLISECONDS = 1000;
const MINUTE_MILLISECONDS = 60 * SECOND_MILLISECONDS;

@Component({
  selector: 'tr-duration-input',
  templateUrl: './duration-input.component.html',
  styleUrls: ['./duration-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DurationInputComponent),
      multi: true,
    },
  ],
})
export class DurationInputComponent implements ControlValueAccessor {
  @Input() public size = 2;
  @Input() public minSeconds = 0;
  @Input() public maxSeconds = 59;
  @Input() public showMinutes = true;

  public disabled = false;
  public minutes = 0;
  public seconds = 0;

  public onChange: (value: number) => void = () => {};
  public onTouched: () => void = () => {};

  public writeValue(duration: number): void {
    this.setMinutesAndSeconds(duration);
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onChangeDuration(): void {
    this.onChange(this.transformToMilliseconds(this.minutes, this.seconds));
  }

  private setMinutesAndSeconds(duration: number): void {
    if (!duration) {
      return;
    }

    let minutes = 0;
    let seconds = 0;

    if (duration >= MINUTE_MILLISECONDS) {
      minutes = Math.floor(duration / MINUTE_MILLISECONDS);
    }

    seconds = Math.floor(
      (duration - minutes * MINUTE_MILLISECONDS) / SECOND_MILLISECONDS
    );

    this.minutes = minutes;
    this.seconds = seconds;
  }

  private transformToMilliseconds(minutes: number, seconds: number): number {
    return minutes * MINUTE_MILLISECONDS + seconds * SECOND_MILLISECONDS;
  }
}
