import {
  Component,
  EventEmitter,
  forwardRef,
  NgZone,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

import {
  BehaviorSubject,
  concat,
  EMPTY,
  iif,
  interval,
  Observable,
  of,
} from 'rxjs';
import {
  distinctUntilChanged,
  scan,
  shareReplay,
  switchMap,
} from 'rxjs/operators';

import { ConfirmService } from '@base_app/shared/services/confirm.service';
import { RecorderState } from './audio-recorder.model';

@Component({
  selector: 'tr-audio-recorder',
  templateUrl: './audio-recorder.component.html',
  styleUrls: ['./audio-recorder.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AudioRecorderComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: AudioRecorderComponent,
      multi: true,
    },
  ],
})
export class AudioRecorderComponent implements ControlValueAccessor, OnDestroy {
  @Output() public audioRecorded = new EventEmitter<{
    file: File | null;
    duration: number;
  }>();
  @Output()
  public statusUpdated = new EventEmitter<RecorderState | null>();

  public resetTimer$ = new BehaviorSubject<void>(undefined);
  public toggleTimer$ = new BehaviorSubject<boolean>(false);
  public timer$: Observable<number> = this.resetTimer$.pipe(
    switchMap(() =>
      concat(
        of(0),
        this.toggleTimer$.pipe(
          distinctUntilChanged(),
          switchMap(timerEnabled =>
            iif(() => timerEnabled, interval(1000), EMPTY)
          ),
          scan(acc => acc + 1, 0)
        )
      )
    ),
    shareReplay()
  );

  public readonly mimeType = 'audio/webm';
  public readonly isRecordingSupported =
    !!navigator?.mediaDevices?.getUserMedia &&
    MediaRecorder.isTypeSupported(this.mimeType);
  public isRecordingAllowed: boolean | null = null;

  public state: RecorderState | null = null;
  public readonly recorderState = RecorderState;

  private readonly fileName = 'recorded-audio.webm';
  private audioFile: File | null = null;

  private mediaRecorder: MediaRecorder | null = null;
  private stream: MediaStream | null = null;
  private audioChunks: Blob[] = [];

  constructor(
    private zone: NgZone,
    private confirmService: ConfirmService
  ) {}

  public ngOnDestroy(): void {
    this.updateState(null);
    this.cleanupStream();
  }

  public writeValue(audioFile: File): void {
    this.audioFile = audioFile;

    if (this.audioFile) {
      this.updateState(RecorderState.RECORDED);
    } else {
      this.state = null;
    }
  }

  public propagateChange = (_value: File | null): void => {};

  public registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(): void {}

  public validate(): { recordInProgress: boolean } | void {
    const isRecordInProgress =
      this?.state === RecorderState.RECORDING ||
      this?.state === RecorderState.PAUSED;

    if (isRecordInProgress) {
      return {
        recordInProgress: true,
      };
    }
  }

  public startRecording(): void {
    navigator.mediaDevices.getUserMedia({ audio: true }).then(
      stream => {
        this.stream = stream;
        this.updateState(RecorderState.RECORDING);

        this.audioFile = null;
        this.propagateChange(this.audioFile);
        this.emitAudioRecorded(this.audioFile);

        this.isRecordingAllowed = true;
        this.mediaRecorder = new MediaRecorder(stream, {
          mimeType: this.mimeType,
        });
        this.mediaRecorder.start();
        this.resetTimer$.next();
        this.toggleTimer$.next(true);

        this.mediaRecorder.ondataavailable = (event: BlobEvent) => {
          this.onAudioChunkAvailable(event.data);
        };

        this.mediaRecorder.onstop = () => {
          if (this.state) {
            this.calculateRecordedDuration();
          }
        };
      },
      () => (this.isRecordingAllowed = false)
    );
  }

  public pauseRecording(): void {
    this.updateState(RecorderState.PAUSED);
    this.mediaRecorder?.pause();
    this.toggleTimer$.next(false);
  }

  public resumeRecording(): void {
    this.updateState(RecorderState.RECORDING);
    this.mediaRecorder?.resume();
    this.toggleTimer$.next(true);
  }

  public stopRecording(): void {
    this.updateState(RecorderState.RECORDED);
    this.mediaRecorder?.stop();
  }

  public restartRecording(): void {
    this.confirmService.confirm(
      {
        header: 'global.audioRecorder.confirmMessage.header',
        confirmMsgPrefix: 'global.audioRecorder.confirmMessage.prefix.restart',
        confirmPostfix: {
          msg: 'global.audioRecorder.confirmMessage.postfix',
        },
      },
      () => this.startRecording()
    );
  }

  public deleteRecording(): void {
    this.confirmService.confirm(
      {
        header: 'global.audioRecorder.confirmMessage.header',
        confirmMsgPrefix: 'global.audioRecorder.confirmMessage.prefix.delete',
        confirmPostfix: {
          msg: 'global.audioRecorder.confirmMessage.postfix',
        },
      },
      () => {
        this.updateState(null);
        this.cleanupRecorder();
        this.audioFile = null;
        this.propagateChange(this.audioFile);
        this.emitAudioRecorded(this.audioFile);
      }
    );
  }

  private onAudioChunkAvailable(chunk: Blob): void {
    this.audioChunks.push(chunk);
  }

  private onAudioRecorded(duration: number): void {
    const audioBlob = new Blob(this.audioChunks);
    const audioFile = new File([audioBlob], this.fileName, {
      type: this.mimeType,
    });

    this.cleanupRecorder();

    this.audioFile = audioFile;
    this.propagateChange(this.audioFile);
    this.emitAudioRecorded(this.audioFile, duration);
  }

  private calculateRecordedDuration(): void {
    const audioBlob = new Blob(this.audioChunks, { type: this.mimeType });

    audioBlob.arrayBuffer().then(buffer => {
      const audioContext = new AudioContext();

      audioContext.decodeAudioData(buffer, decodedData => {
        this.zone.run(() => {
          this.onAudioRecorded(Math.floor(decodedData.duration * 1000));
        });
      });
    });
  }

  private cleanupRecorder(): void {
    if (
      this.mediaRecorder?.state &&
      (this.mediaRecorder.state === 'paused' ||
        this.mediaRecorder.state === 'recording')
    ) {
      this.mediaRecorder.stop();
    }

    this.mediaRecorder = null;
    this.cleanupStream();
    this.resetTimer$.next();
    this.toggleTimer$.next(false);
    this.audioChunks = [];
  }

  private updateState(state: RecorderState | null = null): void {
    this.state = state;
    this.statusUpdated.emit(this.state);
  }

  private cleanupStream(): void {
    this.stream?.getTracks()[0]?.stop();
    this.stream = null;
  }

  private emitAudioRecorded(file: File | null, duration: number = 0): void {
    this.audioRecorded.emit({ file, duration });
  }
}
