import { Component, Input } from '@angular/core';

@Component({
  selector: 'tr-text-truncated',
  templateUrl: './text-truncated.component.html',
  styleUrls: ['./text-truncated.component.scss'],
})
export class TextTruncatedComponent {
  @Input() public text: string | null = '';
  @Input() public limit = 70;
  @Input() public suffix = '...';

  constructor() {}
}
