import { Observable, throwError, timer } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { NullOrUndefinedTyped } from '../model/general.model';
import { NotNullable, Nullable } from './typescript.utils';

export const genericRetryStrategy =
  ({
    maxRetryAttempts = 2,
    scalingDuration = 1000,
    excludedStatusCodes = [400],
  }: {
    maxRetryAttempts?: number;
    scalingDuration?: number;
    excludedStatusCodes?: number[];
  } = {}) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (attempts: Observable<any>) =>
    attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;

        if (
          retryAttempt > maxRetryAttempts ||
          excludedStatusCodes.find(statusCode => statusCode === error.status)
        ) {
          return throwError(() => error);
        }

        return timer(retryAttempt * scalingDuration);
      })
    );

export const inputIsNotNullOrUndefined = <T>(
  input: NullOrUndefinedTyped<T>
): input is T => input !== null && input !== undefined;

export const inputObjectHasNoNullOrUndefinedProperties = <
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends Nullable<Partial<object>>,
>(
  input: T
): input is NotNullable<T> => {
  let hasNullableOrUndefinedProperties = false;

  // eslint-disable-next-line @typescript-eslint/ban-types
  for (const field of Object.keys(input) as Array<keyof object>) {
    if (!inputIsNotNullOrUndefined(input[field])) {
      hasNullableOrUndefinedProperties = true;

      break;
    }
  }

  return !hasNullableOrUndefinedProperties;
};

export const inputObjectPropertiesToNumber = (
  input: Record<string, string>
): Record<string, number> => {
  const recordOfNumbers: Record<string, number> = {};

  for (const field of Object.keys(input)) {
    recordOfNumbers[field] = Number(input[field]);
  }

  return recordOfNumbers;
};

export const isNotNullOrUndefined =
  <T>(): ((source$: Observable<NullOrUndefinedTyped<T>>) => Observable<T>) =>
  (source$: Observable<NullOrUndefinedTyped<T>>) =>
    source$.pipe(filter(inputIsNotNullOrUndefined));

export const isNotNullOrUndefinedObjectProperties =
  <
    // eslint-disable-next-line @typescript-eslint/ban-types
    T extends Nullable<Partial<object>>,
  >(): ((source$: Observable<T>) => Observable<NotNullable<T>>) =>
  (source$: Observable<T>) =>
    source$.pipe(filter(inputObjectHasNoNullOrUndefinedProperties));

export const stringObjectPropertiesToNumbers =
  (): ((
    source$: Observable<Record<string, string>>
  ) => Observable<Record<string, number>>) =>
  (source$: Observable<Record<string, string>>) =>
    source$.pipe(map(inputObjectPropertiesToNumber));
