import { HttpErrorResponse } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';

export const getUserOperator = createAction('[Security] Get User Operator');

export const getUserOperatorSuccess = createAction(
  '[Security] Get User Operator Success',
  props<{ operatorId: number }>()
);

export const getUserOperatorFail = createAction(
  '[Security] Get User Operator Fail',
  props<{ error: HttpErrorResponse }>()
);

export const clearUserOperatorState = createAction(
  '[Security] Clear User Operator'
);
