import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { SecurityService } from '../../security.service';
import {
  getUserPermissions,
  getUserPermissionsFail,
  getUserPermissionsSuccess,
} from './user-permissions.actions';

@Injectable()
export class UserPermissionsEffects {
  public getUserPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserPermissions),
      switchMap(() =>
        this.securityService.getUserPermissions().pipe(
          map(permissions => getUserPermissionsSuccess({ permissions })),
          catchError(error => of(getUserPermissionsFail({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private securityService: SecurityService
  ) {}
}
