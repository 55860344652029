import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'joinBy' })
export class JoinByPipe implements PipeTransform {
  public transform(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    collection: any[],
    property?: string,
    separator: string = ', '
  ): string {
    if (!collection) {
      return '';
    }

    if (!property) {
      return collection.join(separator);
    }

    return collection.map(item => item?.[property]).join(separator);
  }
}
