import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { select, Store } from '@ngrx/store';

import { cloneDeep } from 'lodash-es';
import { of } from 'rxjs';
import { switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { setPreviousRoute } from './previous-route/previous-route.actions';
import { back, forward, go } from './routing.actions';
import { getRoutingStateState } from './routing.selectors';

@Injectable()
export class RouterEffects {
  public navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(go),
        tap(({ path, queryParams, extras }) =>
          this.router.navigate(path, { queryParams, ...extras })
        )
      ),
    { dispatch: false }
  );

  public navigateBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(back),
        tap(() => this.location.back())
      ),
    { dispatch: false }
  );

  public navigateForward$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(forward),
        tap(() => this.location.forward())
      ),
    { dispatch: false }
  );

  public setPreviousRouteState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      withLatestFrom(this.store$.pipe(select(getRoutingStateState))),
      switchMap(([_action, route]) =>
        of(setPreviousRoute({ previousRoute: cloneDeep(route) }))
      )
    )
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private store$: Store,
    private location: Location
  ) {}
}
