import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { FilterRelationConstants } from './filter-relation.constants';

@Component({
  selector: 'tr-filter-relation',
  templateUrl: './filter-relation.component.html',
  styleUrls: ['./filter-relation.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterRelationComponent),
      multi: true,
    },
  ],
})
export class FilterRelationComponent implements ControlValueAccessor {
  @Input() public styleClass = '';
  @Input() public disabled = false;
  @Input() public inputId: string | null = null;

  @Output() public filterChanged = new EventEmitter<void>();

  public filterRelations = FilterRelationConstants.getFilterRelations();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public value: any;

  constructor() {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onChange: (value: any) => void = () => {};

  public onTouched: () => void = () => {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public writeValue(value: any): void {
    if (value) {
      this.value = value;

      return;
    }

    this.value = '';
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public changeValue(): void {
    this.onChange(this.value);
    this.filterChanged.emit();
  }
}
