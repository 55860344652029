<div
  class="message block warning"
  *ngIf="amountOfVehiclesWithBrokenLocations"
>
  {{
    'global.trainsMap.message.vehiclesWithInvalidLocations'
      | translate: { amount: amountOfVehiclesWithBrokenLocations }
  }}
</div>

<div
  class="map leaflet-geoman-map"
  leaflet
  [leafletOptions]="options"
  [leafletMarkerCluster]="markerClusterData"
  [leafletMarkerClusterOptions]="markerClusterOptions"
  (leafletMarkerClusterReady)="markerClusterReady($event)"
  (leafletMapReady)="onMapReady($event)"
></div>

<ng-container *ngIf="map && areaSelect">
  <tr-leaflet-custom-button
    *ngIf="!isDrawModeEnabled; else disableAreaSelectionButton"
    [map]="map"
    [tooltip]="
      'remotePis.instantAnnouncements.editor.target.map.enableSelection'
        | translate
    "
    [iconClass]="'t-icon-view-dashed'"
    (clicked)="enableAreaSelection()"
  >
  </tr-leaflet-custom-button>

  <ng-template #disableAreaSelectionButton>
    <tr-leaflet-custom-button
      [map]="map"
      [tooltip]="
        'remotePis.instantAnnouncements.editor.target.map.disableSelection'
          | translate
      "
      [iconClass]="'t-icon-view-dashed-close'"
      (clicked)="disableAreaSelection()"
    >
    </tr-leaflet-custom-button>
  </ng-template>
</ng-container>
