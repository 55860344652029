import { createReducer, on } from '@ngrx/store';

import {
  clearUserOperatorState,
  getUserOperator,
  getUserOperatorFail,
  getUserOperatorSuccess,
} from './user-operator.actions';
import { userOperatorInitialState } from './user-operator.state';

export const userOperatorReducer = createReducer(
  userOperatorInitialState,
  on(getUserOperator, state => ({
    ...state,
    operatorId: null,
    pending: true,
    resolved: false,
    error: null,
  })),

  on(getUserOperatorSuccess, (state, { operatorId }) => ({
    ...state,
    operatorId,
    pending: false,
    resolved: true,
    error: null,
  })),

  on(getUserOperatorFail, (state, { error }) => ({
    ...state,
    operatorId: null,
    pending: false,
    resolved: true,
    error,
  })),

  on(clearUserOperatorState, () => ({
    ...userOperatorInitialState,
  }))
);
