<p-overlayPanel
  #op
  styleClass="tr-app-switcher"
>
  <div
    class="tr-cards-container horizontal"
    style="flex-wrap: wrap; max-width: 400px; justify-content: space-evenly"
  >
    <a
      class="t-link t-link-no-underline"
      *ngFor="let functionality of functionalities$ | async"
      [routerLink]="[getFunctionalityUrl(functionality)]"
      [queryParams]="{ fromAppSwitcher: true }"
    >
      <tr-card
        class="tr-mini-card"
        [titles]="[functionality.localizationPath | translate]"
        [iconClasses]="functionality.icon"
      >
      </tr-card>
    </a>
  </div>
</p-overlayPanel>

<button
  class="t-button icon inline"
  (click)="op.toggle($event)"
>
  <i
    class="t-icon t-icon-apps"
    aria-hidden="true"
  ></i>
</button>
