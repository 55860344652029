import { createReducer, on } from '@ngrx/store';

import {
  hideSpinnerAction,
  showSpinnerAction,
} from './state-loader-spinner.action';
import { STATE_LOADER_SPINNER_INITIAL_STATE } from './state-loader-spinner.state';

export const stateLoaderSpinnerReducer = createReducer(
  STATE_LOADER_SPINNER_INITIAL_STATE,

  on(showSpinnerAction, state => ({
    ...state,
    showSpinner: true,
  })),

  on(hideSpinnerAction, state => ({
    ...state,
    showSpinner: false,
  }))
);
