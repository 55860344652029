import { HttpErrorResponse } from '@angular/common/http';

export interface IStateWithAsync {
  pending: boolean;
  resolved: boolean;
  error: HttpErrorResponse | null;
}

export const isSuccessfullyResolvedStateWithAsync = (state: IStateWithAsync) =>
  state.resolved && !state.error;

export const isFailedStateWithAsync = (state: IStateWithAsync) =>
  state.resolved && !!state.error;

export const isLoadingOrLoadedStateWithAsync = (state: IStateWithAsync) =>
  state.pending || state.resolved;
