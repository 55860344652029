import { SelectItem } from '@base_app/shared/classes/primengSelectItem';

export enum StatusType {
  MESSAGE_STATISTICS = 'MESSAGE_STATISTICS',
  JOB_UPDATE = 'JOB_UPDATE',
}

export interface IStatusConfig {
  label: string;
  value: string;
}

export class StatusesConstants {
  private static statuses: Readonly<{ [key in StatusType]: IStatusConfig[] }> =
    {
      [StatusType.MESSAGE_STATISTICS]: [
        { label: 'CREATED', value: 'CREATED' },
        { label: 'SENT', value: 'SENT' },
        { label: 'PROCESSED', value: 'PROCESSED' },
        { label: 'ERROR', value: 'ERROR' },
        { label: 'CANCELED', value: 'CANCELED' },
        { label: 'DELIVERED', value: 'DELIVERED' },
        { label: 'NO_QUEUE_FOUND', value: 'NO_QUEUE_FOUND' },
        { label: 'FILE_DOWNLOADED', value: 'FILE_DOWNLOADED' },
        { label: 'FAILED_DOWNLOAD', value: 'FAILED_DOWNLOAD' },
        { label: 'FAILED_UPLOAD', value: 'FAILED_UPLOAD' },
      ],
      [StatusType.JOB_UPDATE]: [
        { label: 'CREATED', value: 'CREATED' },
        { label: 'PENDING', value: 'PENDING' },
        { label: 'ERROR', value: 'ERROR' },
        { label: 'OVERRIDDEN', value: 'OVERRIDDEN' },
        { label: 'COMPLETED', value: 'COMPLETED' },
        { label: 'CANCELLED', value: 'CANCELLED' },
      ],
    };

  public static getStatusesByType(type: StatusType): IStatusConfig[] {
    return [...this.statuses[type]];
  }

  public static parseStatuses(statusConfigs: IStatusConfig[]): SelectItem[] {
    if (
      Array.isArray(statusConfigs) &&
      statusConfigs.some(statusConfig => typeof statusConfig !== 'object')
    ) {
      return this.formSelectItem(statusConfigs);
    }

    if (typeof statusConfigs === 'object') {
      return this.formSelectItem(Object.values(statusConfigs));
    }

    return statusConfigs;
  }

  private static formSelectItem(statusConfigs: IStatusConfig[]): SelectItem[] {
    return statusConfigs.map(statusConfig => new SelectItem(statusConfig));
  }
}
