import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  routerCancelAction,
  routerErrorAction,
  routerNavigatedAction,
  routerRequestAction,
} from '@ngrx/router-store';

import { map } from 'rxjs/operators';

import {
  hideSpinnerAction,
  showSpinnerAction,
} from './state-loader-spinner.action';

@Injectable()
export class StateLoaderSpinnerEffects {
  public showStateLoaderSpinnerOnNavigationStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerRequestAction),
      map(() => showSpinnerAction())
    )
  );

  public hideStateLoaderSpinnerOnNavigationFinished$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction, routerCancelAction, routerErrorAction),
      map(() => hideSpinnerAction())
    )
  );

  constructor(private actions$: Actions) {}
}
