import { Component, OnDestroy, OnInit } from '@angular/core';

import { isNotNullOrUndefined } from '@base_app/shared/utils/rxjs.util';
import { select, Store } from '@ngrx/store';

import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, scan, takeUntil } from 'rxjs/operators';

import { getShowLoaderSpinner } from '../ngrx/state-loader-spinner.selectors';

@Component({
  selector: 'tr-state-loader-spinner',
  templateUrl: './state-loader-spinner.component.html',
  styleUrls: ['./state-loader-spinner.component.scss'],
})
export class StateLoaderSpinnerComponent implements OnInit, OnDestroy {
  public showSpinner = false;

  private showSpinnerDelay = 160;
  private showSpinnerStream$!: Observable<boolean>;
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private store$: Store) {}

  public ngOnInit(): void {
    this.handelShowSpinnerLogic();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  private handelShowSpinnerLogic(): void {
    this.showSpinnerStream$ = this.store$.pipe(
      select(getShowLoaderSpinner),
      isNotNullOrUndefined(),
      takeUntil(this.ngUnsubscribe$)
    );

    merge(
      this.showSpinnerStream$.pipe(
        filter(show => show === true),
        map(() => 1)
      ),
      this.showSpinnerStream$.pipe(
        filter(show => show === false),
        map(() => -1)
      )
    )
      .pipe(
        scan((x, y) => x + y),
        map(result => result > 0),
        debounceTime(this.showSpinnerDelay),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(show => (this.showSpinner = show));
  }
}
