import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { isNotNullOrUndefined } from '@base_app/shared/utils/rxjs.util';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { debounce } from 'lodash-es';

import { FilterPanelService } from '../../filter-panel/filter-panel.service';

@Component({
  selector: 'tr-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
  @Input() public placeholder = '';

  @Input() public filterName = 'name';

  @Input() public autoUpdateFilterOnInput = false;

  @Input() public autoUpdateDebounceTime = 300;

  public searchString = '';

  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  private debouncedUpdateFilter = debounce(
    () => this.updateFilter(),
    this.autoUpdateDebounceTime
  );

  constructor(private filterPanelService: FilterPanelService) {}

  public ngOnInit(): void {
    this.filterPanelService
      .getFilters()
      .pipe(
        isNotNullOrUndefined(),
        filter(filters => !filters[this.filterName]),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(() => (this.searchString = ''));
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  public onKeyup(event: KeyboardEvent): void {
    const value = (event.target as HTMLInputElement).value;

    if (this.autoUpdateFilterOnInput && value) {
      this.debouncedUpdateFilter();
    }

    if (!value || !value.length) {
      this.filterPanelService.resetFilterProp(this.filterName);
    }
  }

  public updateFilter(event?: Event): void {
    event?.preventDefault();

    if (this.searchString) {
      this.filterPanelService.setFilterProp(this.filterName, this.searchString);
    }
  }
}
