import { createReducer, on } from '@ngrx/store';

import {
  loadFeatureFlagsStatuses,
  loadFeatureFlagsStatusesFail,
  loadFeatureFlagsStatusesSuccess,
  setFeatureFlagStatus,
} from './feature-flags-statuses.actions';
import { FeatureFlagsStatusesState } from './feature-flags-statuses.model';

export const featureFlagsStatusesInitialState: FeatureFlagsStatusesState = {
  pending: false,
  resolved: false,
  error: null,
  featureFlagsStatuses: null,
};

export const featureFlagsStatusesReducer = createReducer(
  featureFlagsStatusesInitialState,

  on(loadFeatureFlagsStatuses, () => ({
    ...featureFlagsStatusesInitialState,
    pending: true,
  })),

  on(loadFeatureFlagsStatusesSuccess, (state, { featureFlagsStatuses }) => ({
    ...state,
    featureFlagsStatuses: { ...featureFlagsStatuses },
    pending: false,
    resolved: true,
    error: null,
  })),

  on(loadFeatureFlagsStatusesFail, (_state, { error }) => ({
    ...featureFlagsStatusesInitialState,
    pending: false,
    resolved: true,
    error,
  })),

  on(setFeatureFlagStatus, (state, { featureFlagKey, enabled }) => ({
    ...state,
    featureFlagsStatuses: {
      ...state.featureFlagsStatuses,
      [featureFlagKey]: enabled,
    },
  }))
);
