import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { from, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  constructor(private translate: TranslateService) {}

  public getTranslationPartial(
    langKey: string,
    partialName: string
  ): Observable<object> {
    return from(import(`src/assets/i18n/${langKey}/${partialName}.json`)).pipe(
      map(res => res.default)
    );
  }

  public setTranslation(langKey: string, translations: object): void {
    this.translate.setTranslation(langKey, translations, true);
  }

  public useLanguage(langKey: string): void {
    this.translate.use(langKey);
  }
}
