import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { ConfirmationService } from 'primeng/api';

export interface IConfirmParamsPostfix {
  msg?: string;
  params?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
}
export interface IConfirmParams {
  header?: string;
  acceptLabel?: string;
  rejectLabel?: string;
  confirmMsgPrefix?: string;
  confirmPostfix?: IConfirmParamsPostfix;
}

@Injectable({
  providedIn: 'root',
})
export class ConfirmService {
  constructor(
    private confirmationService: ConfirmationService,
    public translate: TranslateService
  ) {}

  public confirm(
    {
      header,
      acceptLabel = 'global.action.yes',
      rejectLabel = 'global.action.no',
      confirmMsgPrefix = 'global.confirmMsg.baseConfirmMsgPrefix',
      confirmPostfix,
    }: IConfirmParams,
    acceptCallback: () => void,
    rejectCallback: () => void = () => {}
  ): void {
    let messagePrefixTranslated = '';
    let messagePostfixTranslated = '';
    let headerTranslated = '';
    let acceptLabelTranslated = '';
    let rejectLabelTranslated = '';

    if (confirmMsgPrefix) {
      messagePrefixTranslated = this.translate.instant(confirmMsgPrefix);
    }

    if (header) {
      headerTranslated = this.translate.instant(header);
    }

    if (acceptLabel) {
      acceptLabelTranslated = this.translate.instant(acceptLabel);
    }

    if (rejectLabel) {
      rejectLabelTranslated = this.translate.instant(rejectLabel);
    }

    if (confirmPostfix?.msg) {
      messagePostfixTranslated = this.translate.instant(
        confirmPostfix.msg,
        confirmPostfix.params
      );
    }

    this.confirmationService.confirm({
      header: headerTranslated,
      acceptLabel: acceptLabelTranslated,
      rejectLabel: rejectLabelTranslated,
      message: `${messagePrefixTranslated}${messagePostfixTranslated}`,
      accept: () => acceptCallback(),
      reject: () => rejectCallback(),
    });
  }
}
