import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';

import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { getTranslationPartial } from './store/i18n.actions';
import { arePartialsLoadedForCurrentLanguage } from './store/i18n.selectors';

export const i18nPartialsResolve: ResolveFn<boolean> = (
  route: ActivatedRouteSnapshot
): Observable<boolean> => {
  const store$: Store = inject(Store);

  (route.data['i18nPartials'] as string[]).forEach(partialName =>
    store$.dispatch(
      getTranslationPartial({
        partialName,
      })
    )
  );

  return store$.pipe(
    select(arePartialsLoadedForCurrentLanguage(route.data['i18nPartials'])),
    filter(loaded => loaded),
    first()
  );
};
