import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[trOnlyPositiveInteger]',
})
export class OnlyPositiveIntegerDirective {
  @HostListener('keypress', ['$event'])
  public onKeypress(event: KeyboardEvent): void {
    if (!isAllowedCharacter() && !isIntegerChar()) {
      event.preventDefault();
    }

    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    function isIntegerChar(): boolean {
      // eslint-disable-next-line deprecation/deprecation
      return /[0-9]/.test(String.fromCharCode(event.which));
    }

    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    function isAllowedCharacter(): boolean {
      const allowedCodes = [0, 8];

      // eslint-disable-next-line deprecation/deprecation
      return !!(event.metaKey || allowedCodes.indexOf(event.which) !== -1);
    }
  }
}
