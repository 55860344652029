import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { mergeMap } from 'rxjs/operators';

import { EnabledReleaseFeatureFlagsEffects } from './enabled-release-feature-flags/enabled-release-feature-flags.effects';
import { FeatureFlagsStatusesEffects } from './feature-flags-statuses/feature-flags-statuses.effects';
import { clearSecurityState } from './security.actions';
import { clearUserOperatorState } from './user-operator/user-operator.actions';
import { UserOperatorEffects } from './user-operator/user-operator.effects';
import { UserPermissionsEffects } from './user-permissions/user-permissions.effects';

@Injectable()
export class SecurityEffects {
  public clearSecurityState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clearSecurityState),
      mergeMap(() => [clearUserOperatorState()])
    )
  );

  constructor(private actions$: Actions) {}
}

export const SECURITY_EFFECTS = [
  EnabledReleaseFeatureFlagsEffects,
  FeatureFlagsStatusesEffects,
  SecurityEffects,
  UserOperatorEffects,
  UserPermissionsEffects,
];
