import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { IFunctionality } from '@base_app/modules/functionality/store/functionality.model';
import { SelectItem } from '@base_app/shared/classes/primengSelectItem';
import { PrimengHelperService } from '@base_app/shared/services/primeng-helper.service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'tr-functionality-name',
  templateUrl: './functionality-name.component.html',
  styleUrls: ['./functionality-name.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FunctionalityNameComponent),
      multi: true,
    },
  ],
})
export class FunctionalityNameComponent
  implements ControlValueAccessor, OnInit
{
  @Input() public readonlyControl = false;
  @Input() public functionalities$!: Observable<IFunctionality[]>;

  @Output() public valueChanged = new EventEmitter<IFunctionality | null>();

  public value: SelectItem<IFunctionality> | null = null;
  public functionalityNames$!: Observable<SelectItem<IFunctionality>[]>;

  constructor(private primengHelperService: PrimengHelperService) {}

  public onChange: (value: IFunctionality | null) => void = () => {};

  public onTouched: () => void = () => {};

  public writeValue(value: SelectItem<IFunctionality> | null = null): void {
    this.value = value;
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public ngOnInit(): void {
    this.functionalityNames$ = this.functionalities$.pipe(
      map(functionalities =>
        this.primengHelperService.formSelectItem(functionalities, {
          label: 'name',
          value: 'name',
        })
      )
    );
  }
  public changeValue(): void {
    const value = this.value?.value ?? null;

    this.onChange(value);
    this.valueChanged.emit(value);
  }
}
