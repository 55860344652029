<div
  class="tr-filter-control"
  style="min-width: 325px"
>
  <p-multiSelect
    [options]="statuses"
    [(ngModel)]="selectedStatuses"
    appendTo="body"
    [defaultLabel]="placeholder | translate"
    (onChange)="updateFilters()"
    [filter]="false"
  ></p-multiSelect>
</div>
