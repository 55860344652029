import { Routes } from '@angular/router';

import { BaseShellComponent } from '@base_app/modules/shared-components/base-shell/base-shell.component';

import { i18nGlobalPartialGuard } from '../i18n/i18n-global-partial.guard';
import { i18nPartialsDeactivateGuard } from '../i18n/i18n-partials-deactivate.guard';
import { i18nPartialsResolve } from '../i18n/i18n-partials.resolve';
import { securityPermissionsGuard } from '../security/guards/permissions.guard';
import { securityEnabledReleaseFeatureFlagsGuard } from '../security/guards/release-feature-flags.guard';
import { securityUserOperatorGuard } from '../security/guards/user-operator.guard';

export const appRoutes: Routes = [
  {
    path: '',
    canActivate: [i18nGlobalPartialGuard],
    resolve: {
      i18n: i18nPartialsResolve,
    },
    canDeactivate: [i18nPartialsDeactivateGuard],
    data: {
      i18nPartials: ['language'],
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/operators',
      },
      {
        path: 'operators',
        canActivate: [
          securityUserOperatorGuard,
          securityPermissionsGuard,
          securityEnabledReleaseFeatureFlagsGuard,
        ],
        loadChildren: () =>
          import('@base_app/modules/operator/operator.module').then(
            m => m.OperatorModule
          ),
      },
      {
        path: 'versions',
        loadChildren: () =>
          import('@base_app/modules/versions/versions.module').then(
            m => m.VersionsModule
          ),
      },
      {
        path: 'forbidden',
        component: BaseShellComponent,
        loadChildren: () =>
          import('@base_app/modules/forbidden/forbidden.module').then(
            m => m.ForbiddenModule
          ),
      },
      {
        path: 'wrong-realm',
        loadChildren: () =>
          import('@base_app/modules/wrong-realm/wrong-realm.module').then(
            m => m.WrongRealmModule
          ),
      },
      {
        path: 'administration',
        canActivate: [
          securityPermissionsGuard,
          securityEnabledReleaseFeatureFlagsGuard,
        ],
        loadChildren: () =>
          import('@base_app/modules/administration/administration.module').then(
            m => m.AdministrationModule
          ),
      },
    ],
  },
];
