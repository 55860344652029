import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { isEqual } from 'lodash-es';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { IPlannedMaintenance } from './planned-maintenance.model';

@Injectable({
  providedIn: 'root',
})
export class PlannedMaintenanceService {
  private readonly acceptedMaintenanceStorageKey = 'acceptedPlannedMaintenance';

  constructor(private http: HttpClient) {}

  public getNextPlannedMaintenance(): Observable<IPlannedMaintenance | void> {
    const url = `${environment.api}/feature-flags/v1/api/feature-flags-wayside/maintenance/planned/next`;

    return this.http.get<IPlannedMaintenance | void>(url);
  }

  public acceptPlannedMaintenance(
    plannedMaintenance: IPlannedMaintenance
  ): void {
    sessionStorage.setItem(
      this.acceptedMaintenanceStorageKey,
      JSON.stringify(plannedMaintenance)
    );
  }

  public checkPlannedMaintenanceAcceptance(
    plannedMaintenance: IPlannedMaintenance
  ): boolean {
    const acceptedMaintenanceString = sessionStorage.getItem(
      this.acceptedMaintenanceStorageKey
    );

    if (!acceptedMaintenanceString) {
      return false;
    }

    const acceptedMaintenance = JSON.parse(acceptedMaintenanceString);

    return isEqual(plannedMaintenance, acceptedMaintenance);
  }
}
