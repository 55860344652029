import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { SimpleChangesTyped } from '@base_app/shared/utils/angular.utils';

@Component({
  selector: 'tr-form-control-wrapper',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
// TODO: rename component and folder/files to reflect component selector and make it less confusing
export class InputComponent implements OnChanges {
  @Input() public control: AbstractControl | null = null;
  @Input() public forceRequired: boolean | null = null;
  @Input() public horizontalLayout: boolean | null = null;
  @Input() public noGutter = false;
  @Input() public amountOfColumns = 2;

  @Input() public label = '';
  @Input() public elementIdForLabel = '';
  @Input() public tooltip: string | null = null;
  @Input() public warningIconTooltip = false;
  @Input() public tooltipPosition: 'right' | 'left' | 'top' | 'bottom' =
    'right';
  @Input() public tooltipAfterContent = false;

  public gridColumnsAmount = '';

  public get isValid(): boolean {
    if (!this.control) {
      return true;
    }

    return !(this.control.invalid && this.control.touched);
  }

  public get isRequired(): boolean {
    if (this.forceRequired) {
      return true;
    }

    // TODO: in some places empty label is used as placeholder for proper elements alignment
    // so also check for existence of label before showing asterisk
    // better would be to require label or not to show label if it's not provided
    if (!this.label || !this.control || !this.control.validator) {
      return false;
    }

    const validator = this.control.validator({} as AbstractControl);

    return validator && validator['required'];
  }

  public ngOnChanges({
    amountOfColumns,
  }: SimpleChangesTyped<InputComponent>): void {
    if (amountOfColumns?.currentValue && this.horizontalLayout) {
      this.gridColumnsAmount = `p-col-${this.amountOfColumns}`;
    }
  }
}
