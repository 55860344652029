import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { select, Store } from '@ngrx/store';

import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';

import { getUserPermissions } from '../ngrx/user-permissions/user-permissions.actions';
import {
  getSecurityUserPermissions,
  isSecurityUserPermissionsSuccessfullyResolved,
} from '../ngrx/user-permissions/user-permissions.selectors';
import {
  ENTITY_SPECIFIC_PERMISSION_ALL_ENTITIES_ID,
  generateEntitySpecificPermissionName,
} from '../utils/ngx-permissions.utils';

export const securityPermissionsGuard: CanActivateFn =
  (): Observable<boolean> => {
    const store$: Store = inject(Store);
    const permissionsService: NgxPermissionsService = inject(
      NgxPermissionsService
    );

    store$.dispatch(getUserPermissions());

    return store$.pipe(
      select(isSecurityUserPermissionsSuccessfullyResolved),
      filter(isSuccessfullyResolved => isSuccessfullyResolved),
      switchMap(() => store$.pipe(select(getSecurityUserPermissions))),
      tap(userPermissions => {
        if (userPermissions?.general) {
          permissionsService.addPermission([...userPermissions.general]);
        }

        if (userPermissions?.entitySpecific) {
          userPermissions.entitySpecific.forEach(entitySpecificPermission => {
            if (!entitySpecificPermission.allEntities) {
              permissionsService.addPermission([
                ...entitySpecificPermission.entityIds.map(entityId =>
                  generateEntitySpecificPermissionName(
                    entitySpecificPermission.permission,
                    entitySpecificPermission.entityType,
                    entityId
                  )
                ),
              ]);
            } else {
              permissionsService.addPermission(
                generateEntitySpecificPermissionName(
                  entitySpecificPermission.permission,
                  entitySpecificPermission.entityType,
                  ENTITY_SPECIFIC_PERMISSION_ALL_ENTITIES_ID
                )
              );
            }
          });
        }
      }),
      map(() => true),
      first()
    );
  };
