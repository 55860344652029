import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

export const ENTITY_SPECIFIC_PERMISSION_ENTITY_ID_SEPARATOR = '$';

export const ENTITY_SPECIFIC_PERMISSION_ALL_ENTITIES_ID = 'ALL';

export const generateEntitySpecificPermissionName = (
  permission: string,
  entityType: string,
  entityId: string | number
): string => {
  return `${permission}_${entityType}_${ENTITY_SPECIFIC_PERMISSION_ENTITY_ID_SEPARATOR}${entityId}`;
};

export const getForbiddenPageUrl = (operatorId: string): string =>
  `/operators/${operatorId}/functionality/forbidden`;

export const getForbiddenPageUrlForNgxPermissionsRedirect = (
  _rejectedPermissionName: string,
  activateRouteSnapshot: ActivatedRouteSnapshot,
  _routerStateSnapshot: RouterStateSnapshot
): string => getForbiddenPageUrl(activateRouteSnapshot.params['operatorId']);
