<div class="tr-filter-control {{ styleClass }}">
  <h5
    class="t-h5"
    *ngIf="showCaption"
  >
    {{ caption | translate }}
  </h5>

  <tr-autocomplete-dropdown
    [itemsForAutocomplete]="itemsForAutocomplete"
    [fieldToDisplay]="fieldToDisplay"
    [propNamesToFilterBy]="propNamesToFilterBy"
    [placeholder]="placeholder"
    [(ngModel)]="selectedItems"
    (selected)="updateFilters($event)"
    (unselected)="updateFilters($event)"
    [shortenSelectedTo]="shortenSelectedTo"
    [appendTo]="appendTo"
  ></tr-autocomplete-dropdown>
</div>
