import { createReducer, on } from '@ngrx/store';

import {
  clearUserPermissionsState,
  getUserPermissions,
  getUserPermissionsFail,
  getUserPermissionsSuccess,
} from './user-permissions.actions';
import { userPermissionsInitialState } from './user-permissions.state';

export const userPermissionsReducer = createReducer(
  userPermissionsInitialState,
  on(getUserPermissions, state => ({
    ...state,
    userPermissions: null,
    pending: true,
    resolved: false,
    error: null,
  })),

  on(getUserPermissionsSuccess, (state, { permissions }) => ({
    ...state,
    userPermissions: permissions,
    pending: false,
    resolved: true,
    error: null,
  })),

  on(getUserPermissionsFail, (state, { error }) => ({
    ...state,
    userPermissions: null,
    pending: false,
    resolved: true,
    error,
  })),

  on(clearUserPermissionsState, () => ({
    ...userPermissionsInitialState,
  }))
);
