import { Directive, HostListener, Inject, Input } from '@angular/core';

import { WINDOW } from '@base_app/core/services/window.service';

@Directive({
  selector: '[trMaxLength]',
})
export class MaxLengthDirective {
  @Input()
  public trMaxLength?: number;

  constructor(@Inject(WINDOW) private window: Window) {}

  @HostListener('keypress', ['$event'])
  public onKeypress(event: KeyboardEvent): void {
    if (!this.trMaxLength) {
      return;
    }

    const selection = this.window.getSelection && this.window.getSelection();
    const maxLength = this.trMaxLength;

    if (!hasSelectedText() && !exceedLimit()) {
      event.preventDefault();
    }

    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    function exceedLimit(): boolean {
      const allowedCodes = [0, 8];

      if (
        event.metaKey ||
        // eslint-disable-next-line deprecation/deprecation
        allowedCodes.indexOf(event.which) !== -1 ||
        !(event.target as HTMLInputElement).value
      ) {
        return true;
      }

      return (event.target as HTMLInputElement).value.length < maxLength;
    }

    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    function hasSelectedText(): boolean {
      return !!selection && selection.toString() !== '';
    }
  }
}
