<div class="tr-filter-control">
  <p-dropdown
    [options]="configs"
    [(ngModel)]="selectedConfigValue"
    (onChange)="updateFilters()"
    appendTo="body"
    [placeholder]="placeholder | translate"
  >
    <ng-template
      let-item
      pTemplate="selectedItem"
    >
      {{ item.label | translate }}
    </ng-template>

    <ng-template
      let-item
      pTemplate="item"
    >
      <div>
        {{ item.label | translate }}
      </div>
    </ng-template>
  </p-dropdown>
</div>
