import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { SimpleChangesTyped } from '@base_app/shared/utils/angular.utils';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

import { Menu } from 'primeng/menu';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { isEqual } from 'lodash-es';

import { IItemActionsSetComponentAction } from './item-actions-set.component.model';
import {
  IMenuItemAction,
  IMenuItemWithTranslations,
} from './item-actions-set.model';

@Component({
  selector: 'tr-item-actions-set',
  templateUrl: './item-actions-set.component.html',
  styleUrls: ['./item-actions-set.component.scss'],
  providers: [TranslatePipe],
})
export class ItemActionsSetComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public showEdit = true;
  @Input() public showView = false;
  @Input() public showArchive = true;
  @Input() public hideUnArchive = false;
  @Input() public showDelete = false;
  @Input() public showDownload = false;
  @Input() public showExport = false;
  @Input() public showDuplicate = false;
  @Input() public initialItems: IItemActionsSetComponentAction[] = [];
  @Input() public mainIcon = 't-icon-more-vert';

  @Output() public action: EventEmitter<string> = new EventEmitter<string>();
  @Output() public edit: EventEmitter<null> = new EventEmitter<null>();
  @Output() public view: EventEmitter<null> = new EventEmitter<null>();
  @Output() public archive: EventEmitter<true | null> = new EventEmitter<
    true | null
  >();
  @Output() public delete: EventEmitter<null> = new EventEmitter<null>();
  @Output() public duplicate: EventEmitter<null> = new EventEmitter<null>();
  @Output() public download: EventEmitter<null> = new EventEmitter<null>();
  @Output() public export: EventEmitter<null> = new EventEmitter<null>();

  @ViewChild('menu', { static: true }) private menu!: Menu;

  public items: IMenuItemWithTranslations[] = [];

  private actions: IMenuItemAction = {
    archive: {
      icon: 't-icon t-icon-archive',
      translatePath: 'global.action.archive',
      command: () => this.archive.emit(true),
    },
    delete: {
      icon: 't-icon t-icon-delete',
      translatePath: 'global.action.delete',
      command: () => this.delete.emit(null),
    },
    duplicate: {
      icon: 't-icon t-icon-copy',
      translatePath: 'global.action.duplicate',
      command: () => this.duplicate.emit(null),
    },
    edit: {
      icon: 't-icon t-icon-settings',
      translatePath: 'global.action.edit',
      command: () => this.edit.emit(null),
    },
    export: {
      icon: 't-icon t-icon-download',
      translatePath: 'global.action.export',
      command: () => this.export.emit(null),
    },
    view: {
      icon: 't-icon t-icon-eye',
      translatePath: 'global.action.view',
      command: () => this.view.emit(null),
    },
    unarchive: {
      icon: 't-icon t-icon-archive',
      translatePath: 'global.action.unarchive',
      command: () => this.archive.emit(null),
    },
    download: {
      icon: 't-icon t-icon-download',
      translatePath: 'global.action.download',
      command: () => this.download.emit(null),
    },
  };

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private translate: TranslateService,
    private translatePipe: TranslatePipe,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public ngOnInit(): void {
    this.initActions();
    this.handleMenuItemsTranslation();

    this.translate.onLangChange
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => this.handleMenuItemsTranslation());
  }

  public ngOnChanges({
    initialItems,
  }: SimpleChangesTyped<ItemActionsSetComponent>): void {
    if (
      (initialItems?.currentValue && !initialItems.previousValue) ||
      (initialItems?.previousValue &&
        !isEqual(initialItems?.currentValue, initialItems?.previousValue))
    ) {
      this.initActions();
      this.handleMenuItemsTranslation();
    }
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  public toggleMenu(event: Event): void {
    // simulate click on body to close other menus
    this.document.getElementsByTagName('body')[0].click();
    event.stopPropagation();
    event.preventDefault();
    this.menu.toggle(event);
    // need to set it to close menu on first click
    // as it requires 2 clicks with stopPropagation
    this.menu.preventDocumentDefault = false;
  }

  private handleMenuItemsTranslation(): void {
    this.items.forEach(
      menuItem =>
        (menuItem.label = this.translatePipe.transform(menuItem.translatePath))
    );
  }

  private initActions(): void {
    if (!this.initialItems?.length) {
      this.initDefaultActions();
    } else {
      this.items = this.initialItems.map(item => ({
        ...item,
        command: () => this.action.emit(item.actionType),
      }));
    }
  }

  private initDefaultActions(): void {
    this.items = [];

    if (this.showEdit) {
      this.items.push(this.actions['edit']);
    }

    if (this.showView) {
      this.items.push(this.actions['view']);
    }

    if (this.showArchive) {
      this.items.push(this.actions['archive']);
    }

    if (this.showDelete) {
      this.items.push(this.actions['delete']);
    }

    if (this.showDuplicate) {
      this.items.push(this.actions['duplicate']);
    }

    if (this.showDownload) {
      this.items.push(this.actions['download']);
    }

    if (this.showExport) {
      this.items.push(this.actions['export']);
    }

    if (!this.hideUnArchive && this.showDelete && !this.showEdit) {
      this.items.push(this.actions['unarchive']);
    }
  }
}
