import { isSuccessfullyResolvedStateWithAsync } from '@base_app/shared/model/ngrx.model';
import { createSelector } from '@ngrx/store';

import { getSecurityState } from '../security.selectors';

export const getSecurityUserPermissionsState = createSelector(
  getSecurityState,
  security => security?.userPermissions || null
);

export const getSecurityUserPermissions = createSelector(
  getSecurityUserPermissionsState,
  permissionsState => permissionsState?.userPermissions || null
);

export const isSecurityUserPermissionsSuccessfullyResolved = createSelector(
  getSecurityUserPermissionsState,
  isSuccessfullyResolvedStateWithAsync
);
