import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { getTranslationPartial } from './store/i18n.actions';
import { arePartialsLoadedForCurrentLanguage } from './store/i18n.selectors';

export const i18nGlobalPartialGuard: CanActivateFn =
  (): Observable<boolean> => {
    const store$: Store = inject(Store);
    const globalPartialName = 'global';

    store$.dispatch(getTranslationPartial({ partialName: globalPartialName }));

    return store$.pipe(
      select(arePartialsLoadedForCurrentLanguage([globalPartialName])),
      filter(loaded => loaded),
      first()
    );
  };
