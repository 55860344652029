export class DateUtil {
  public static getDateUTC<T>(date: Date | T): number | T {
    if (date instanceof Date) {
      return Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      );
    }

    return date;
  }

  public static getDateWithClearedTimezoneOffset(date: Date): Date {
    const dateCopy = new Date(date.getTime());
    const dateWithTimezoneOffset = new Date(date.getTime());
    const offsetInHours = date.getTimezoneOffset() / 60;
    const hours = date.getHours();

    dateCopy.setHours(hours - offsetInHours);

    const hoursWithOffset = dateCopy.getHours();

    dateWithTimezoneOffset.setHours(hoursWithOffset);

    return dateWithTimezoneOffset;
  }

  public static getDateEnd(date: Date): number {
    return Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      23,
      59,
      59
    );
  }
}
