import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateCut',
})
export class TranslateCutPipe implements PipeTransform {
  public transform(value: string, cutIndex: number): string {
    if (value?.split) {
      return value.split('|')[cutIndex];
    }

    return value;
  }
}
