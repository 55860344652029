import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { ArchivedStatusFilter } from './archived-filter.model';

@Component({
  selector: 'tr-archived-filter',
  templateUrl: './archived-filter.component.html',
  styleUrls: ['./archived-filter.component.scss'],
})
export class ArchivedFilterComponent implements OnInit {
  @Input() public statusFilter: ArchivedStatusFilter | null = null;

  @Output() public statusFilterChanged =
    new EventEmitter<ArchivedStatusFilter>();

  public statusFilterControl!: UntypedFormControl;

  public statusFilters = [
    { name: 'Active', value: ArchivedStatusFilter.ACTIVE },
    { name: 'Archived', value: ArchivedStatusFilter.ARCHIVED },
    { name: 'All', value: ArchivedStatusFilter.ALL },
  ];

  public ngOnInit(): void {
    this.statusFilterControl = new UntypedFormControl(
      this.statusFilters.find(statusF => statusF.value === this.statusFilter)
    );
  }

  public filterStatusChange(): void {
    this.statusFilterChanged.emit(this.statusFilterControl.value.value);
  }
}
