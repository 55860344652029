import { HttpErrorResponse } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';

import { IPlannedMaintenance } from './planned-maintenance.model';

export const loadPlannedMaintenance = createAction(
  '[Planned Maintenance] Load',
  props<{ intervalInSeconds: number }>()
);

export const loadPlannedMaintenanceSuccess = createAction(
  '[Planned Maintenance] Load Success',
  props<{ plannedMaintenance: IPlannedMaintenance | null }>()
);

export const loadPlannedMaintenanceFail = createAction(
  '[Planned Maintenance] Load Fail',
  props<{ error: HttpErrorResponse }>()
);

export const acceptMaintenanceBannerAction = createAction(
  '[Planned Maintenance] Accept',
  props<{ plannedMaintenance: IPlannedMaintenance }>()
);

export const showMaintenanceBannerAction = createAction(
  '[Planned Maintenance] Show banner'
);

export const hideMaintenanceBannerAction = createAction(
  '[Planned Maintenance] Hide banner'
);
