import { Component } from '@angular/core';

@Component({
  selector: 'tr-base-shell',
  templateUrl: './base-shell.component.html',
  styleUrls: ['./base-shell.component.scss'],
})
export class BaseShellComponent {
  constructor() {}
}
