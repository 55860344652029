import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { isNotNullOrUndefined } from '@base_app/shared/utils/rxjs.util';

import { CheckboxChangeEvent } from 'primeng/checkbox';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { FilterPanelService } from '../../filter-panel/filter-panel.service';

@Component({
  selector: 'tr-checkbox-filter',
  templateUrl: './checkbox-filter.component.html',
  styleUrls: ['./checkbox-filter.component.scss'],
})
export class CheckboxFilterComponent implements OnInit, OnDestroy {
  @Input() public filterName = '';
  @Input() public label = '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public checkedValue: any = true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public unCheckedValue: any = false;

  public value = false;

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private filtersService: FilterPanelService) {}

  public ngOnInit(): void {
    this.filtersService
      .getFilters()
      .pipe(
        isNotNullOrUndefined(),
        filter(filters => !filters[this.filterName]),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(() => (this.value = false));
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  public updateFilter({ checked }: CheckboxChangeEvent): void {
    this.filtersService.setFilterProp(
      this.filterName,
      checked ? this.checkedValue : this.unCheckedValue
    );
  }
}
