import { HttpHeaders, HttpResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  ViewChild,
} from '@angular/core';

import { SimpleChangesTyped } from '@base_app/shared/utils/angular.utils';

@Component({
  selector: 'tr-download-tag',
  templateUrl: './download-tag.component.html',
  styleUrls: ['./download-tag.component.scss'],
})
export class DownloadTagComponent implements OnChanges {
  @Input() public downloadData?: HttpResponse<Blob> | null = null;
  @Input() public type = 'application/octet-stream';

  @ViewChild('downloadLink', { static: true })
  private downloadLink!: ElementRef<HTMLAnchorElement>;

  constructor(private renderer: Renderer2) {}

  public ngOnChanges({
    downloadData,
  }: SimpleChangesTyped<DownloadTagComponent>): void {
    if (downloadData?.currentValue?.body) {
      const blob = new Blob([downloadData.currentValue.body], {
        type: this.type,
      });
      const name = this.getFileNameFromHeader(
        downloadData.currentValue.headers
      );
      const url = window.URL.createObjectURL(blob);
      const aEl = this.downloadLink.nativeElement;

      this.renderer.setAttribute(aEl, 'href', url);
      this.renderer.setAttribute(aEl, 'download', name);
      aEl.click();
      this.renderer.setAttribute(aEl, 'href', '');
      this.renderer.setAttribute(aEl, 'download', '');
    }
  }

  private getFileNameFromHeader(headers: HttpHeaders): string {
    const name = headers.get('Content-Disposition');

    return name?.split('"')[1] || '';
  }
}
