import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { NgElement, WithProperties } from '@angular/elements';

import {
  defaultLeafletMapOptions,
  defaultLeafletMapPathOptions,
  defaultLeafletMapTrainIconOptions,
  defaultLeafletMapZoomControlOptions,
} from '@base_app/shared/model/leaflet.model';
import { ITrain } from '@base_app/shared/model/train.model';
import { SimpleChangesTyped } from '@base_app/shared/utils/angular.utils';
import { isNotNullOrUndefined } from '@base_app/shared/utils/rxjs.util';
import '@geoman-io/leaflet-geoman-free';
import { TranslateService } from '@ngx-translate/core';
import { booleanWithin } from '@turf/turf';

import {
  control,
  featureGroup,
  FeatureGroup,
  icon,
  Map,
  MapOptions,
  Marker,
  marker,
  MarkerClusterGroup,
  MarkerClusterGroupOptions,
  Polygon,
} from 'leaflet';
import 'leaflet.markercluster';
import { cloneDeep, isEmpty } from 'lodash-es';
import { filter, Subject, takeUntil } from 'rxjs';

import { TrainsMapMarkerPopupComponent } from './trains-map-marker-popup/trains-map-marker-popup.component';
import { ITrainMarkerOptions } from './trains-map.component.model';
import { isTrainLocationValid } from './trains-map.utils';

@Component({
  selector: 'tr-trains-map',
  templateUrl: './trains-map.component.html',
  styleUrls: ['./trains-map.component.scss'],
})
export class TrainsMapComponent implements OnChanges, OnDestroy {
  @Input() public trains?: ITrain[] | null;
  @Input() public resetMapTimestamp: number | null = null;
  @Input() public areaSelect = false;

  @Input() public selectedTrainFakeId: string | null = null;

  @Output() public trainSelected = new EventEmitter<string | null>(true);
  @Output() public trainsSelected = new EventEmitter<string[] | null>(true);

  public amountOfVehiclesWithBrokenLocations?: number;

  public map?: Map;
  public options: MapOptions = cloneDeep(defaultLeafletMapOptions);

  public markerClusterGroup?: MarkerClusterGroup;
  public markerClusterData: Marker[] = [];
  public markerClusterOptions!: MarkerClusterGroupOptions;

  public isDrawModeEnabled = false;
  public areaSelectionGroup: FeatureGroup = featureGroup();

  private readonly trainIcon = icon(defaultLeafletMapTrainIconOptions);

  private fakeTrainIdOfOpenedPopup: string | null = null;
  private fakeTrainIdOfPopupToReopen: string | null = null;
  private reopenedMarker: Marker | null = null;

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private readonly zone: NgZone,
    private translate: TranslateService
  ) {}

  public ngOnChanges({
    trains,
    selectedTrainFakeId,
    resetMapTimestamp,
  }: SimpleChangesTyped<TrainsMapComponent>): void {
    if (trains?.currentValue?.length) {
      this.fakeTrainIdOfPopupToReopen = this.fakeTrainIdOfOpenedPopup;

      this.markerClusterData = this.generateTrainsMarkers(this.trains ?? []);
      this.amountOfVehiclesWithBrokenLocations =
        this.calculateAmountOfVehiclesWithBrokenLocations(this.trains ?? []);

      if (this.markerClusterGroup) {
        this.markerClusterGroup.refreshClusters();
      }

      if (this.fakeTrainIdOfPopupToReopen !== null && this.map) {
        this.reopenPopupByTrainFakeId(
          this.fakeTrainIdOfPopupToReopen,
          this.markerClusterData,
          this.map
        );
      }
    } else if (trains) {
      this.markerClusterData = [];
      this.amountOfVehiclesWithBrokenLocations = 0;
    }

    if (
      selectedTrainFakeId?.currentValue &&
      selectedTrainFakeId.currentValue !== this.fakeTrainIdOfOpenedPopup
    ) {
      this.openMarkerClusterGroupPopupByTrainIndexAndZoomTo(
        selectedTrainFakeId.currentValue,
        this.markerClusterGroup
      );
    }

    if (resetMapTimestamp?.currentValue && !resetMapTimestamp.firstChange) {
      this.recenterMap();
    }
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  public onMapReady(map: Map): void {
    this.map = map;

    this.map.attributionControl.setPrefix('');
    control.zoom(defaultLeafletMapZoomControlOptions).addTo(this.map);
    this.overrideMapLinesStyling();
    this.connectGeomanTranslationsToAppTranslations();

    if (this.areaSelect) {
      this.handleTrainSelection();
    }

    setTimeout(() => map.invalidateSize(), 0);
  }

  public markerClusterReady(group: MarkerClusterGroup): void {
    this.markerClusterGroup = group;

    this.recenterMap();
  }

  public enableAreaSelection(): void {
    this.isDrawModeEnabled = true;

    this.map?.pm.enableDraw('Polygon');
  }

  public disableAreaSelection(): void {
    this.map?.pm.disableDraw();

    this.isDrawModeEnabled = false;
  }

  private handleTrainSelection(): void {
    this.map?.on('pm:create', event => {
      const selectionShape = event.layer as Polygon;
      const selectionShapeGeoJson = selectionShape.toGeoJSON();
      const selectedTrains: string[] = [];

      if (this.markerClusterData.length > 0) {
        this.markerClusterData.forEach(item => {
          if (booleanWithin(item.toGeoJSON(), selectionShapeGeoJson)) {
            selectedTrains.push(
              (item.options as ITrainMarkerOptions).trainFakeId
            );
          }
        });
      }

      if (selectedTrains.length > 0) {
        this.zone.run(() => this.trainsSelected.emit(selectedTrains));
      }

      this.map?.removeLayer(event.layer);
      this.zone.run(() => this.disableAreaSelection());
    });
  }

  private connectGeomanTranslationsToAppTranslations(): void {
    this.translate
      .stream('leafletGeoman')
      .pipe(
        isNotNullOrUndefined(),
        filter(loaded => !!loaded),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(translations => this.map?.pm.setLang('en', translations));
  }

  private overrideMapLinesStyling(): void {
    this.map?.pm.setGlobalOptions({
      ...this.map?.pm.getGlobalOptions(),
      hintlineStyle: {
        color: defaultLeafletMapPathOptions.color,
        weight: defaultLeafletMapPathOptions.weight,
      },
      templineStyle: {
        ...defaultLeafletMapPathOptions,
      },
      pathOptions: {
        ...defaultLeafletMapPathOptions,
      },
    });
  }

  private recenterMap(): void {
    if (
      this.markerClusterGroup &&
      this.map &&
      !isEmpty(this.markerClusterGroup.getBounds())
    ) {
      this.map.fitBounds(this.markerClusterGroup.getBounds());
    }
  }

  private generateTrainsMarkers(trains: ITrain[]): Marker[] {
    return trains
      .filter(train => isTrainLocationValid(train.location))
      .reduce((markers, trainWithValidLocation) => {
        const dataMarker = marker(
          {
            lat: trainWithValidLocation.location.latitude,
            lng: trainWithValidLocation.location.longitude,
          },
          {
            icon: this.trainIcon,
            trainFakeId: trainWithValidLocation.fakeId,
          } as ITrainMarkerOptions
        );

        dataMarker.bindPopup(
          () => this.createFleetMapMarkerPopupElement(trainWithValidLocation),
          { className: 'leaflet-trains-map-marker-popup' }
        );

        dataMarker.on('popupopen', () =>
          this.trainPopupOpened(trainWithValidLocation.fakeId)
        );

        dataMarker.on('popupclose', () =>
          this.trainPopupClosed(trainWithValidLocation.fakeId)
        );

        markers.push(dataMarker);

        return markers;
      }, [] as Marker[]);
  }

  private createFleetMapMarkerPopupElement(
    train: ITrain
  ): NgElement & WithProperties<TrainsMapMarkerPopupComponent> {
    const trainsMapMarkerPopupElement: NgElement &
      WithProperties<TrainsMapMarkerPopupComponent> = document.createElement(
      'trains-map-marker-popup'
    );

    trainsMapMarkerPopupElement.train = train;

    return trainsMapMarkerPopupElement;
  }

  private calculateAmountOfVehiclesWithBrokenLocations(
    trains?: ITrain[]
  ): number {
    if (!trains) {
      return 0;
    }

    return trains
      .filter(train => !isTrainLocationValid(train.location))
      .reduce(
        (amountOfInvalidVehicles, train) =>
          amountOfInvalidVehicles + train.vehicles.length,
        0
      );
  }

  private trainPopupOpened(trainFakeId: string): void {
    if (this.fakeTrainIdOfOpenedPopup !== trainFakeId) {
      this.fakeTrainIdOfOpenedPopup = trainFakeId;
      this.zone.run(() => this.trainSelected.emit(trainFakeId));
    }
  }

  private trainPopupClosed(trainFakeId: string): void {
    if (this.fakeTrainIdOfOpenedPopup === trainFakeId) {
      this.fakeTrainIdOfOpenedPopup = null;
      this.zone.run(() => this.trainSelected.emit(null));
    }
  }

  private reopenPopupByTrainFakeId(
    trainFakeId: string,
    markers: Marker[],
    map: Map
  ): void {
    const markerToReOpenPopup: Marker | null =
      markers.find(
        data =>
          (data.options as ITrainMarkerOptions).trainFakeId === trainFakeId
      ) || null;

    if (markerToReOpenPopup) {
      markerToReOpenPopup.addTo(map).openPopup();
    }

    if (this.reopenedMarker) {
      map.removeLayer(this.reopenedMarker);
    }

    this.reopenedMarker = markerToReOpenPopup;
  }

  private openMarkerClusterGroupPopupByTrainIndexAndZoomTo(
    trainFakeId: string,
    markerClusterGroup?: MarkerClusterGroup
  ): void {
    const markerToOpenPopup = markerClusterGroup
      ?.getLayers()
      .find(
        layerMarker =>
          ((layerMarker as Marker).options as ITrainMarkerOptions)
            .trainFakeId === trainFakeId
      ) as Marker;

    if (markerToOpenPopup) {
      markerClusterGroup?.zoomToShowLayer(markerToOpenPopup, () =>
        markerToOpenPopup?.openPopup()
      );
    } else {
      this.zone.run(() =>
        this.trainSelected.emit(this.fakeTrainIdOfOpenedPopup)
      );
    }
  }
}
