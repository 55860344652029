import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';

import { PageTitleEffects } from './page-title.effects';

@NgModule({
  imports: [CommonModule, EffectsModule.forFeature([PageTitleEffects])],
})
export class PageTitleModule {}
