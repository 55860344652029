import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PreloaderModule } from '@televic/prime-widgets/preloader';

import { stateLoaderSpinnerReducer } from './ngrx';
import { StateLoaderSpinnerEffects } from './ngrx/state-loader-spinner.effects';
import { StateLoaderSpinnerComponent } from './state-loader-spinner';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('stateLoaderSpinner', stateLoaderSpinnerReducer),
    EffectsModule.forFeature([StateLoaderSpinnerEffects]),
    PreloaderModule,
  ],
  declarations: [StateLoaderSpinnerComponent],
  exports: [StateLoaderSpinnerComponent],
})
export class StateLoaderSpinnerModule {}
