import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'tr-url-state-indicator',
  templateUrl: './url-state-indicator.component.html',
  styleUrls: ['./url-state-indicator.component.scss'],
})
export class UrlStateIndicatorComponent {
  @HostBinding('class.horizontal') public horizontal = true;
  @HostBinding('class.align-center') public alignCenter = true;
  @HostBinding('class.line') public line = true;
  @HostBinding('class.bottom') public bottom = true;

  constructor() {}
}
