import { Component, Input, OnInit } from '@angular/core';

import { INavLink } from '@base_app/shared/interfaces/nav-link';

import { Observable } from 'rxjs';

import { TabsNavService } from './tabs-nav.service';

@Component({
  selector: 'tr-tabs-nav',
  templateUrl: './tabs-nav.component.html',
  styleUrls: ['./tabs-nav.component.scss'],
  providers: [TabsNavService],
})
export class TabsNavComponent implements OnInit {
  @Input() public navLinks: INavLink[] = [];

  public visibleFlag$!: Observable<boolean>;

  constructor(private navService: TabsNavService) {}

  public ngOnInit(): void {
    this.visibleFlag$ = this.navService.getVisibilityFlag();
  }
}
