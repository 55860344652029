import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { DateUtil } from '@base_app/shared/utils/date.utils';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FilterPanelService } from '../../filter-panel/filter-panel.service';

@Component({
  selector: 'tr-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
})
export class DateRangeComponent implements OnInit, OnDestroy {
  @Input() public beforeLabel = 'global.filters.dateRange.fromLabel';

  @Input() public beforeDateFilterName = 'beforeCreatedAt';
  @Input() public afterDateFilterName = 'afterCreatedAt';

  public afterDate: Date | null = null;
  public beforeDate: Date | null = null;

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private filtersService: FilterPanelService) {}

  public ngOnInit(): void {
    this.filtersService
      .getFilters()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(filters => {
        if (!filters[this.afterDateFilterName]) {
          this.afterDate = null;
        }

        if (!filters[this.beforeDateFilterName]) {
          this.beforeDate = null;
        }
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  public updateMinDateFilter(date: Date): void {
    const utc = DateUtil.getDateUTC(date);

    this.afterDate = date;
    this.filtersService.setFilterProp(this.afterDateFilterName, utc);
  }

  public updateMaxDateFilter(date: Date): void {
    const utc = DateUtil.getDateEnd(date);

    this.beforeDate = date;
    this.filtersService.setFilterProp(this.beforeDateFilterName, utc);
  }
}
