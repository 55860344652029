import { isSuccessfullyResolvedStateWithAsync } from '@base_app/shared/model/ngrx.model';
import { createSelector } from '@ngrx/store';

import { getSecurityState } from '../security.selectors';

export const getEnabledReleaseFeatureFlagsState = createSelector(
  getSecurityState,
  securityState => securityState.enabledReleaseFeatureFlags
);

export const getEnabledReleaseFeatureFlagsLoadingStatus = createSelector(
  getEnabledReleaseFeatureFlagsState,
  enabledReleaseFeatureFlagsState => enabledReleaseFeatureFlagsState.pending
);

export const getEnabledReleaseFeatureFlags = createSelector(
  getEnabledReleaseFeatureFlagsState,
  enabledReleaseFeatureFlagsState =>
    enabledReleaseFeatureFlagsState.enabledReleaseFeatureFlags
);

export const areEnabledReleaseFeatureFlagsSuccessfullyResolved = createSelector(
  getEnabledReleaseFeatureFlagsState,
  isSuccessfullyResolvedStateWithAsync
);

export const isReleaseFeatureFlagEnabled = (releaseFeatureFlagKey: string) =>
  createSelector(getEnabledReleaseFeatureFlags, enabledReleaseFeatureFlags => {
    if (!enabledReleaseFeatureFlags) {
      return false;
    }

    return !!enabledReleaseFeatureFlags.find(
      enabledReleaseFeatureFlag =>
        enabledReleaseFeatureFlag === releaseFeatureFlagKey
    );
  });
