import { HttpClient } from '@angular/common/http';

import { lastValueFrom } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { IEnvironment } from '../../../../environments/environment.model';

export const appInitializer =
  (
    http: HttpClient,
    appInitializerDependencies: (() => void)[]
  ): (() => Promise<void>) =>
  (): Promise<void> =>
    new Promise(async (resolve, reject) => {
      try {
        const data = await lastValueFrom(
          http.get<IEnvironment>('src/environments/environment.overrides.json')
        );

        Object.assign(environment, data);

        await Promise.all(appInitializerDependencies.map(dep => dep()));

        resolve(undefined);
      } catch (error) {
        reject(error);
      }
    });
