import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { I18nService } from './i18n.service';
import { I18nEffects } from './store/i18n.effects';
import { i18nReducer } from './store/i18n.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('i18n', i18nReducer),
    EffectsModule.forFeature([I18nEffects]),
    TranslateModule.forRoot(),
  ],
  exports: [TranslateModule],
  providers: [I18nService, I18nEffects],
})
export class I18nModule {}
