<div class="horizontal align-center">
  <ng-container *ngIf="showMinutes">
    <p-inputNumber
      [(ngModel)]="minutes"
      [size]="size"
      [min]="0"
      [max]="99"
      [showButtons]="true"
      (onBlur)="onTouched()"
      (ngModelChange)="onChangeDuration()"
    >
    </p-inputNumber>
    <span class="inputs-spacer">:</span>
  </ng-container>

  <p-inputNumber
    [(ngModel)]="seconds"
    [size]="size"
    [min]="minutes ? 0 : minSeconds"
    [max]="maxSeconds"
    [showButtons]="true"
    (onBlur)="onTouched()"
    (ngModelChange)="onChangeDuration()"
  >
  </p-inputNumber>
</div>
