<div [id]="identifier">
  <button
    class="t-button leaflet-custom-button {{ buttonClass }}"
    type="button"
    [pTooltip]="tooltip"
    [tooltipDisabled]="!tooltip"
    [ngClass]="{
      'leaflet-custom-button-default-shape': defaultShape,
      't-shadow-highlighted': defaultShape
    }"
    (click)="clicked.emit()"
  >
    <i
      class="t-icon {{ iconClass }}"
      aria-hidden="true"
      *ngIf="iconClass"
    ></i>
    <ng-container *ngIf="label">{{ label | translate }}</ng-container>
  </button>
</div>
