import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgxPermissionsModule } from 'ngx-permissions';

import { PipesModule } from './pipes/pipes.module';

@NgModule({
  imports: [CommonModule, PipesModule],
  exports: [NgxPermissionsModule, PipesModule],
})
export class SharedModule {}
