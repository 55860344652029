<div class="tr-filter-control {{ styleClass }}">
  <p-dropdown
    [(ngModel)]="value"
    [options]="filterRelations"
    [disabled]="disabled"
    optionLabel="label"
    [placeholder]="'global.filters.filterRelation.placeholder' | translate"
    [style]="{ width: '100%' }"
    (onChange)="changeValue()"
    [inputId]="$any(inputId)"
    appendTo="body"
  >
    <ng-template
      let-item
      pTemplate="selectedItem"
    >
      {{ item.label | translate }}
    </ng-template>

    <ng-template
      let-item
      pTemplate="item"
    >
      <div>
        {{ item.label | translate }}
      </div>
    </ng-template>
  </p-dropdown>
</div>
