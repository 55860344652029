import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { IFunctionality } from '@base_app/modules/functionality/store/functionality.model';
import { getUserFunctionalities } from '@base_app/modules/functionality/store/user-functionalities/user-functionalities.selectors';
import { select, Store } from '@ngrx/store';

import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { FilterPanelService } from '../../filter-panel/filter-panel.service';

@Component({
  selector: 'tr-functionality-name-filter',
  templateUrl: './functionality-name-filter.container.html',
})
export class FunctionalityNameFilterContainerComponent
  implements OnInit, OnDestroy
{
  @Input() public styleClass = '';
  @Input() public showTitle = true;
  @Input() public filterPropName = 'functionalityName';

  public selectedFunctionality: string | null = '';
  public functionalities$!: Observable<IFunctionality[]>;

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private store$: Store,
    private filtersService: FilterPanelService
  ) {}

  public ngOnInit(): void {
    this.functionalities$ = this.store$.pipe(select(getUserFunctionalities));

    this.filtersService
      .getFilters()
      .pipe(
        filter(filters => !filters[this.filterPropName]),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(() => (this.selectedFunctionality = null));
  }

  public updateFilters(): void {
    this.filtersService.setFilterProp(
      this.filterPropName,
      this.selectedFunctionality
    );
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
