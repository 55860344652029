import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { EffectsModule } from '@ngrx/effects';
import {
  FullRouterStateSerializer,
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';

import { RoutingCustomSerializer } from './routing-custom-serializer';
import { ROUTING_CONFIG } from './routing.config';
import { appRoutes } from './routing.routes';
import { previousRouteReducer } from './store/previous-route/previous-route.reducer';
import { RouterEffects } from './store/routing.effects';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes, ROUTING_CONFIG),
    EffectsModule.forFeature([RouterEffects]),
    StoreModule.forFeature('previousRoute', previousRouteReducer),
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
    }),
  ],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: RoutingCustomSerializer,
    },
  ],
  exports: [RouterModule],
})
export class RoutingModule {}
