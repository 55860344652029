import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { getCurrentStateParams } from '@base_app/core/routing/store/routing.selectors';
import { IEntitySpecificRequiredRole } from '@base_app/core/security/security.model';
import {
  ENTITY_SPECIFIC_PERMISSION_ALL_ENTITIES_ID,
  generateEntitySpecificPermissionName,
} from '@base_app/core/security/utils/ngx-permissions.utils';
import { select, Store } from '@ngrx/store';

import { isEqual } from 'lodash-es';
import { NgxPermissionsService } from 'ngx-permissions';
import { distinctUntilChanged, Subject, take, takeUntil } from 'rxjs';

@Directive({
  selector: '[trNgxPermissionsOnlyEntitySpecific]',
})
export class NgxPermissionsOnlyEntitySpecificDirective
  implements OnInit, OnDestroy
{
  @Input()
  public trNgxPermissionsOnlyEntitySpecificElse?: TemplateRef<unknown>;

  private permissionsToCheck: string[] = [];
  private entitySpecificRequiredRoles: IEntitySpecificRequiredRole[] = [];
  private isHidden = true;

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private store$: Store,
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private permissionsService: NgxPermissionsService
  ) {}

  @Input()
  public set trNgxPermissionsOnlyEntitySpecific(
    entitySpecificRequiredRoles: IEntitySpecificRequiredRole[]
  ) {
    this.entitySpecificRequiredRoles = entitySpecificRequiredRoles;
    this.generatePermissionsToCheckAndUpdateView();
  }

  public ngOnInit(): void {
    this.permissionsService.permissions$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => this.updateView());

    this.store$
      .pipe(
        select(getCurrentStateParams),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(() => this.generatePermissionsToCheckAndUpdateView());
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  private async updateView(): Promise<void> {
    if (await this.checkPermission()) {
      if (this.isHidden) {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();

      if (this.trNgxPermissionsOnlyEntitySpecificElse) {
        this.viewContainer.createEmbeddedView(
          this.trNgxPermissionsOnlyEntitySpecificElse
        );
      }
    }
  }

  private generatePermissionsToCheckAndUpdateView(): void {
    this.store$
      .pipe(
        select(getCurrentStateParams),
        take(1),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(routeParams => {
        this.permissionsToCheck = [];

        if (this.entitySpecificRequiredRoles.length > 0) {
          this.entitySpecificRequiredRoles.forEach(role => {
            this.permissionsToCheck.push(
              generateEntitySpecificPermissionName(
                role.permission,
                role.entityType,
                routeParams[role.stateParamName]
              )
            );
            this.permissionsToCheck.push(
              generateEntitySpecificPermissionName(
                role.permission,
                role.entityType,
                ENTITY_SPECIFIC_PERMISSION_ALL_ENTITIES_ID
              )
            );
          });
        }

        this.updateView();
      });
  }

  private async checkPermission(): Promise<boolean> {
    return this.permissionsService.hasPermission(this.permissionsToCheck);
  }
}
