import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { QuotePipe } from './quote.pipe';
import { TranslateCutPipe } from './translate-cut.pipe';

const PIPES = [TranslateCutPipe, QuotePipe];

@NgModule({
  imports: [CommonModule],
  declarations: [...PIPES],
  providers: [...PIPES],
  exports: [...PIPES],
})
export class PipesModule {}
