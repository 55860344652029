import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { SecurityService } from '../../security.service';
import {
  loadEnabledReleaseFeatureFlags,
  loadEnabledReleaseFeatureFlagsFail,
  loadEnabledReleaseFeatureFlagsSuccess,
} from './enabled-release-feature-flags.actions';

@Injectable()
export class EnabledReleaseFeatureFlagsEffects {
  public loadEnabledReleaseFeatureFlags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEnabledReleaseFeatureFlags),
      switchMap(() =>
        this.securityService.getEnabledReleaseFeatureFlags().pipe(
          map(enabledReleaseFeatureFlags =>
            loadEnabledReleaseFeatureFlagsSuccess({
              enabledReleaseFeatureFlags,
            })
          ),
          catchError(error => of(loadEnabledReleaseFeatureFlagsFail({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private securityService: SecurityService
  ) {}
}
